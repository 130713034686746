import React, { useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Link,
  Redirect,
} from "react-router-dom";
import TitleSetter from "../../hoc/TitleSetter";

import { RiArrowDropUpFill } from "react-icons/ri";
import { DiJavascript } from "react-icons/di";
import Introduccion from "./tutorials/Introduccion";
import ComoUsar from "./tutorials/ComoUsar";
import Sintaxis from "./tutorials/Sintaxis";
import Fundamentos from "./tutorials/Fundamentos";
import Mostrar from "./tutorials/Mostrar";
import Comentarios from "./tutorials/Comentarios";
import Variables from "./tutorials/Variables";
import Operadores from "./tutorials/Operadores";
import Datos from "./tutorials/Datos";
import Funciones from "./tutorials/Funciones";
import Objetos from "./tutorials/Objetos";
import PageNotFound from "../../../PageNotFound";

import "../../sidebar/sidebar.css";

const normalStyle = {
  display: "inline-block",
  transition: "1s, transform 1s",
};

const transformedStyle = {
  ...normalStyle,
  transform: "rotate(180deg)",
};

const JavaScript = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/javascript/");

  const [isActive, setActive] = useState("false");
  const [iconTransition, setIconTransition] = useState(false);

  const handleToggleAndClick = () => {
    setActive(!isActive);
    setIconTransition(!iconTransition);
    document.body.classList.toggle("no-scroll");
  };

  const handleLinkClick = () => {
    setActive("false");
    setIconTransition(false);
  };

  return (
    <div className="side-nav-div text-neutral-200">
      <div className="toggle-button-lesson">
        <div className="toggle-con mb-4">
          <button className="lesson-button" onClick={handleToggleAndClick}>
            js / {splitLocation}
            <div style={iconTransition ? transformedStyle : normalStyle}>
              <RiArrowDropUpFill size={25} />
            </div>
          </button>
        </div>
      </div>
      <div className={`${isActive ? "sidenav" : "activeSideNav"}`}>
        <DiJavascript style={{ color: "#F0DB4F" }} />
        <div className="list-wrapper">
          <div className="red-line" />

          <div className="list-item-wrapper">
            <Link
              to={`${path}/introducción-a-javascript`}
              onClick={handleLinkClick}
            >
              <div
                className={
                  splitLocation[1] === "introducción-a-javascript"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "introducción-a-javascript"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Introducción a JS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-y-javascript`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-y-javascript"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-y-javascript"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Cómo usar JavaScript
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/javascript-sintaxis`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "javascript-sintaxis"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "javascript-sintaxis"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Sintaxis JS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link
              to={`${path}/javascript-fundamentos`}
              onClick={handleLinkClick}
            >
              <div
                className={
                  splitLocation[1] === "javascript-fundamentos"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "javascript-fundamentos"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Fundamentos JS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/javascript-mostrar`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "javascript-mostrar"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "javascript-mostrar"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Mostrar JS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link
              to={`${path}/javascript-comentarios`}
              onClick={handleLinkClick}
            >
              <div
                className={
                  splitLocation[1] === "javascript-comentarios"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "javascript-comentarios"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Comentarios JS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/javascript-variables`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "javascript-variables"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "javascript-variables"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Variables JS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link
              to={`${path}/javascript-operadores`}
              onClick={handleLinkClick}
            >
              <div
                className={
                  splitLocation[1] === "javascript-operadores"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "javascript-operadores"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Operadores JS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/tipos-de-datos-javascript`}>
              <div
                className={
                  splitLocation[1] === "tipos-de-datos-javascript"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "tipos-de-datos-javascript"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Tipos De Datos JS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/funciones-javascript`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "funciones-javascript"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "funciones-javascript"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Funciones JS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/objetos-javascript`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "objetos-javascript"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "objetos-javascript"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Objetos JS
                </span>
                <div className="list-text" />
              </div>
              <div className="white-line" />
            </Link>
          </div>
        </div>
      </div>
      <Switch>
        <Route
          exact
          path={path}
          render={() => <Redirect to={`${path}/introducción-a-javascript`} />}
        />
        <Route path={`${path}/introducción-a-javascript`}>
          <TitleSetter title="Introducción a JavaScript">
            <Introduccion />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-y-javascript`}>
          <TitleSetter title="Cómo usar JavaScript con HTML">
            <ComoUsar />
          </TitleSetter>
        </Route>
        <Route path={`${path}/javascript-sintaxis`}>
          <TitleSetter title="Sintaxis Basicas de JavaScript">
            <Sintaxis />
          </TitleSetter>
        </Route>
        <Route path={`${path}/javascript-fundamentos`}>
          <TitleSetter title="Fundamentos Básicos de JavaScript">
            <Fundamentos />
          </TitleSetter>
        </Route>
        <Route path={`${path}/javascript-mostrar`}>
          <TitleSetter title="Cómo mostrar JavaScript">
            <Mostrar />
          </TitleSetter>
        </Route>
        <Route path={`${path}/javascript-comentarios`}>
          <TitleSetter title="Comentarios en JavaScript">
            <Comentarios />
          </TitleSetter>
        </Route>
        <Route path={`${path}/javascript-variables`}>
          <TitleSetter title="Variables en JavaScript">
            <Variables />
          </TitleSetter>
        </Route>
        <Route path={`${path}/javascript-operadores`}>
          <TitleSetter title="Operadores en JavaScript">
            <Operadores />
          </TitleSetter>
        </Route>
        <Route path={`${path}/tipos-de-datos-javascript`}>
          <TitleSetter title="Tipos de Datos en JavaScript">
            <Datos />
          </TitleSetter>
        </Route>
        <Route path={`${path}/funciones-javascript`}>
          <TitleSetter title="Funciones en JavaScript">
            <Funciones />
          </TitleSetter>
        </Route>
        <Route path={`${path}/objetos-javascript`}>
          <TitleSetter title="Objetos en JavaScript">
            <Objetos />
          </TitleSetter>
        </Route>
        <Route path={`${path}/*`}>
          <TitleSetter title="Page Not Found">
            <PageNotFound />
          </TitleSetter>
        </Route>
      </Switch>
    </div>
  );
};

export default JavaScript;
