import React from "react";
import ReactPlayer from "react-player/youtube";
import HtmlCodeSnippetComponent from "../../NewSnippets/HtmlCodeSnippetComponent";
import HtmlCssCodeSnippetComponent from "../../NewSnippets/HtmlCssCodeSnippetComponent";
import HtmlCssExternalCodeSnippetComponent from "../../NewSnippets/HtmlCssExternalCodeSnippetComponent"
import HtmlCssJsCodeSnippetComponent from "../../NewSnippets/HtmlCssJsCodeSnippetComponent";

const Nuevo = () => {
  return (
    <div className="text-white min-h-screen overflow-hidden">
      <div className="md:ml-64 md:mr-40 mb-0 md:pt-8 lg:px-2 lg:text-lg text-base p-0">
        <h2 className="md:text-3xl text-2xl font-bold mb-4">
          Introducción a HTML
        </h2>
        <HtmlCodeSnippetComponent />
        <div className="border-b border-solid border-transparent mb-4" />
        <HtmlCssCodeSnippetComponent />
        <div className="border-b border-solid border-transparent mb-4" />
        <HtmlCssExternalCodeSnippetComponent />
        <div className="border-b border-solid border-transparent mb-4" />
        <HtmlCssJsCodeSnippetComponent />
        <h2 className="md:text-3xl text-2xl font-bold mb-4">Video Tutorial</h2>
        <div className="relative pt-[56.25%]">
          <ReactPlayer
            className="absolute top-0 left-0"
            url="https://youtu.be/q1Y5ipF85tA?si=UHyAUVX1TZSdRITt"
            width="100%"
            height="100%"
          />
        </div>
        <div className="mb-8"></div>
      </div>
    </div>
  );
};

export default Nuevo;
