import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const ComoUsar = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Cómo usar javascript</h2>
        <p>Como usar Javascript con HTML</p>
        <ul className='lesson-ul'>
          <li>
            En html, Javascript siempre debe estar insertado entre las etiquetas <code><span className='tag'>&lt;script&gt;&lt;/script&gt;</span></code>
          </li>
        </ul>
        <p>Hay 3 formas de agregar JS a tu proyecto html</p>
        <ul className='lesson-ul'>
          <li>
            Dentro de la etiqueta <code><span className='tag'>&lt;head&gt;</span></code>
          </li>
          <li>
            Dentro de la etiqueta <code><span className='tag'>&lt;body&gt;</span></code>
          </li>
          <li>
            Externo
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Dentro de la etiqueta <code><span className='tag'>&lt;head&gt;</span></code></h3>
        <p>Insertaremos nuestro código javascript dentro de nuestra etiqueta <code><span className='tag'>&lt;head&gt;&lt;/head&gt;</span></code></p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsHead}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>Dentro de la etiqueta <code><span className='tag'>&lt;body&gt;</span></code></h3>
        <p>Insertaremos nuestro código javascript dentro de nuestra etiqueta <code><span className='tag'>&lt;body&gt;&lt;/body&gt;</span></code></p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsBody}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>Externo Javascript</h3>
        <ul className='lesson-ul'>
          <li>
            Los archivos JavaScript tienen la extensión <strong>.js</strong>
          </li>
          <li>
            Un archivo externo es útil cuando utiliza el mismo javascript en varias páginas
          </li>
        </ul>
        <p>Los scripts externos no contienen las etiquetas <code><span className='tag'>&lt;script&gt;&lt;/script&gt;</span></code></p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsExterno}
                </code>
              </pre>
            </div>
          </div>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsExternoScript}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default ComoUsar
