import React from 'react';

// Define the main colors used in VS Code's theme for JavaScript
const vscodeTheme = {
    background: '#272727',
    text: '#D4D4D4',
    jsKeyword: '#C586C0',
    jsFunction: '#DCDCAA',
    jsString: '#CE9178',
    jsNumber: '#B5CEA8',
    jsBoolean: '#569CD6',
    jsNull: '#569CD6',
    comment: '#6A9955',
};

// A component that displays a JavaScript code snippet focusing on data types
const JavaScriptDataTypesCodeSnippetComponent = () => {
    const explanationStyle = {
        backgroundColor: vscodeTheme.background,
        color: vscodeTheme.text,
        padding: '20px',
        fontFamily: 'Consolas, "Courier New", monospace',
        borderRadius: '5px',
        marginBottom: '1.5rem',
        borderLeft: '4px solid'
    };

    const codeStyle = {
        ...explanationStyle,
        border: '1px solid #fff',
        marginTop: '10px',
        whiteSpace: 'pre',
        overflowX: 'auto',
        borderLeft: '1px solid #fff'
    };

    const explanation = 'Este ejemplo demuestra varios tipos de datos de JavaScript, como cadenas, números, valores booleanos, matrices y objetos.';

    return (
        <div className='mb-8'>
            <div style={explanationStyle}>{explanation}</div>
            <pre style={codeStyle}>
                <span style={{ color: vscodeTheme.comment }}>{"//"} String</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>const</span> myString <span style={{ color: vscodeTheme.jsKeyword }}>=</span> <span style={{ color: vscodeTheme.jsString }}>'Hello, world!'</span>;<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"//"} Number</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>const</span> myNumber <span style={{ color: vscodeTheme.jsKeyword }}>=</span> <span style={{ color: vscodeTheme.jsNumber }}>42</span>;<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"//"} Boolean</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>const</span> myBoolean <span style={{ color: vscodeTheme.jsKeyword }}>=</span> <span style={{ color: vscodeTheme.jsBoolean }}>true</span>;<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"//"} Array</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>const</span> myArray <span style={{ color: vscodeTheme.jsKeyword }}>=</span> [<span style={{ color: vscodeTheme.jsNumber }}>1</span>, <span style={{ color: vscodeTheme.jsNumber }}>2</span>, <span style={{ color: vscodeTheme.jsNumber }}>3</span>];<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"//"} Object</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>const</span> myObject <span style={{ color: vscodeTheme.jsKeyword }}>=</span> {'{'}<br />
                &nbsp;&nbsp;name: <span style={{ color: vscodeTheme.jsString }}>'Alice'</span>,<br />
                &nbsp;&nbsp;age: <span style={{ color: vscodeTheme.jsNumber }}>25</span><br />
                {'}'};<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"//"} Null and Undefined</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>const</span> myNull <span style={{ color: vscodeTheme.jsKeyword }}>=</span> <span style={{ color: vscodeTheme.jsNull }}>null</span>;<br />
                <span style={{ color: vscodeTheme.jsKeyword }}>let</span> myUndefined;<br />
            </pre>
        </div>
    );
};

export default JavaScriptDataTypesCodeSnippetComponent;
