import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import '../../../sidebar/sidebar.css'

const HtmlJs = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Cómo usar Javascript con HTML</h2>
        <p>Introducción al uso de Javascript con HTML</p>
        <ul className='lesson-ul'>
          <li>
            <strong>Javascript</strong> es como puedes hacer tu sitio web interactivo
          </li>
          <li>
            Agregando la etiqueta <code><span className='tag'>&lt;script&gt;</span></code> es como implementas y aceptas <strong>javascript</strong> en tu pagina de HTML
          </li>
        </ul>
        <p>Hay 2 formas de agregar Javascript a su proyecto HTML</p>
        <ul className='lesson-ul'>
          <li>
            Internal
          </li>
          <li>
            External
          </li>
          <li>
            Siempre agrega la etiqueta <code><span className='tag'>&lt;script&gt;</span></code> al ultimo de la etiqueta <code><span className='tag'>&lt;body&gt;</span></code>. Esto permite que tu CSS y HTML se carguen primero
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Interno JS</h3>
        <ul className='lesson-ul'>
          <li>
            JavaScript interno se puede agregar agregando la etiquera <code><span className='tag'>&lt;script&gt;</span></code> al final de la etiqueta <code><span className='tag'>&lt;body&gt;</span></code>
          </li>
          <li>
            Sugerimos usar esta opción solo si está agregando un tamaño pequeño de código javascript
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlJSInterno}
                </code>
              </pre>
            </div>
          </div>
          <div>
            {/* No Result */}
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Externo JS</h3>
        <ul className='lesson-ul'>
          <li>
            Javascript externo se agrega de la misma manera que el javascript interno, pero en lugar de agregar código en la etiqueta <code><span className='tag'>&lt;script&gt;</span></code> lo agregas en otro archivo con la extencion ".js"
          </li>
          <li>
            Javascript externo es la mejor manera porque agregar código CSS, JS en su proyecto html puede ser difícil de mantener y entender
          </li>
          <li>
            Al usar un archivo Javascript externo, separan su código javascript de su código HTML
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlJSExterno}
                </code>
              </pre>
            </div>
          </div>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlJSExternoJS}
                </code>
              </pre>
            </div>
          </div>
          <div>
            {/* No Result */}
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default HtmlJs
