import React from 'react';

// Define the main colors used in VS Code's theme for HTML and CSS
const vscodeTheme = {
    background: '#272727',
    text: '#D4D4D4',
    tag: '#569CD6',
    attributeName: '#9CDCFE',
    attributeValue: '#CE9178',
    cssProperty: '#9CDCFE',
    cssValue: '#CE9178',
    comment: '#6A9955',
};

// A component that displays an HTML and CSS code snippet with syntax highlighting
const HtmlCssCodeSnippetComponent = () => {
    const explanationStyle = {
        backgroundColor: vscodeTheme.background,
        color: vscodeTheme.text,
        padding: '20px',
        fontFamily: 'Consolas, "Courier New", monospace',
        borderRadius: '5px',
        marginBottom: '1.5rem',
        borderLeft: '4px solid'
    };

    const codeStyle = {
        ...explanationStyle,
        border: '1px solid #fff',
        marginTop: '10px',
        whiteSpace: 'pre',
        overflowX: 'auto',
        borderLeft: '1px solid #fff'
    };

    const explanation = 'Este ejemplo muestra un fragmento HTML complejo con estilos CSS en línea y varios elementos de sintaxis.';

    return (
        <div className='mb-8'>
            <div style={explanationStyle}>{explanation}</div>
            <pre style={codeStyle}>
                <span style={{ color: vscodeTheme.comment }}>&lt;!-- HTML Structure --&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;div</span> <span style={{ color: vscodeTheme.attributeName }}>style</span>=<span style={{ color: vscodeTheme.attributeValue }}>"background-color: #1E1E1E; color: #D4D4D4;"</span><span style={{ color: vscodeTheme.tag }}>&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;h1&gt;</span>Welcome<span style={{ color: vscodeTheme.tag }}>&lt;/h1&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;p</span> <span style={{ color: vscodeTheme.attributeName }}>style</span>=<span style={{ color: vscodeTheme.attributeValue }}>"margin-left: 20px;"</span><span style={{ color: vscodeTheme.tag }}>&gt;</span>This is a paragraph.<span style={{ color: vscodeTheme.tag }}>&lt;/p&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;/div&gt;</span>
            </pre>
        </div>
    );
};

export default HtmlCssCodeSnippetComponent;
