import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import '../../../sidebar/sidebar.css'

const Attributes = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Attributes HTML</h2>
        <p>Qué es un atributo HTML</p>
        <ul className='lesson-ul'>
          <li> Atributos son palabras especiales que se utilizan dentro de la etiqueta incial para controlar el comportamiento del elemento</li>
          <li>Todos los elementos HTML pueden tener atributos</li>
        </ul>
        <hr className='style-line' />
        <h3>El Atributo "src"</h3>
        <p>Añadiendo el atributo <span className='text_editor_attribute'>src</span> especifica el URL de la dirección URL de la imagen .</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlAtributoSrc}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h1>Hola Mundo</h1>
              <p>Este es un párrafo</p>
            </div>
          </div>
        </Tabs>

        <hr className='style-line' />

        <h3>El Atributo "style"</h3>
        <p>El atributo <span className='text_editor_attribute'>style</span> le permite especificar las reglas de la hoja de estilo en (CSS) dentro del elemento.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlAtributoStyle}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 style={{ color: 'red' }}>Texto de ejemplo..</h3>
            </div>
          </div>
        </Tabs>

        <hr className='style-line' />

        {/* fix me */}

        <h3>El Atributo "href"</h3>
        <p>El atributo <span className='text_editor_attribute'> &lt;href&gt;</span> especifica la URL de la página a la que va el enlace.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlAtributoHref}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <a href='www.hablacode.org'><h3>hablacode</h3></a>
            </div>
          </div>
        </Tabs>

        <hr className='style-line' />

        <h3>El Atributo "title"</h3>
        <p>
          El valor del atributo <span className='text_editor_attribute'>title</span> se mostrará cuando pase el mouse sobre el párrafo
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlAtributoTitle}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 title='titulo ejemplo...'>Bienvenido</h3>
            </div>
          </div>
        </Tabs>

        <hr className='style-line' />

        <h3>El Atributo "lang"</h3>
        <ul className='lesson-ul'>
          <li>
            El idioma del documento se puede declarar en la etiqueta
            <span className='tag'> &lt;html&gt;</span>
          </li>
          <li>
            El idioma se declara con el atributo <span className='text_editor_attribute'>lang</span>
          </li>
          <li>
            Declarar un idioma es importante para aplicaciones de accesibilidad (lectores de pantalla) y motores de búsqueda
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlAtributoLang}
                </code>
              </pre>
            </div>
          </div>
          <div label='' />
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Attributes
