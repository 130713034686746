import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const Datos = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Tipos De Datos</h2>
        <p>Javascript variables pueden contener muchos tipos de datos diferentes.</p>
        <ul className='lesson-ul'>
          <li>strings (cadenas)</li>
          <li>numbers (numeros)</li>
          <li>booleans (verdadero o falso)</li>
          <li>arrays (estructura de datos)</li>
          <li>objects (objeto)</li>
        </ul>
        <p>Los tipos de datos en la programación son un concepto importante para saber.</p>
        <p>Para que un programa se ejecute correctamente, deben conocer el tipo de datos. Todos los lenguajes de programación usan variables y probablemente todos usan el mismo concepto, pero están escritos de manera un poco diferente.</p>

        <hr className='style-line' />

        <h3>Declaración y Uso</h3>
        <p>A continuación tenemos 3 tipos de datos populares utilizados en JavaScript:</p>
        <ul className='lesson-ul'>
          <li>Las cadenas se representan como palabras: "hola"</li>
          <li>Los números como un número: 25</li>
          <li>los objetos se escriben con llaves: &#123; &#125;</li>
        </ul>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploUno}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <p>Al agregar un número a una cadena, JavaScript tratará el número como una cadena.</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploTwo}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <p>Javascript tratará el ejemplo anterior como:</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploThree}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />

        <p>Javascript evalúa expresiones de izquierda a derecha</p>

        <strong>Ejemplo 1:</strong>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploFour}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>Hola2019</p>
            </div>
          </div>
        </Tabs>
        <strong>Ejemplo 2:</strong>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploFive}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>2019Hola</p>
            </div>
          </div>
        </Tabs>

        <p><strong>Ejemplo 1</strong>: Como el primer operando es una cadena, todo lo demás se trata como una cadena.</p>

        <p><strong>Ejemplo 2</strong>: JavaScript trata nuestra expresión como un número hasta que agrega una cadena y luego trata la expresión como una cadena.</p>

        <hr className='style-line' />

        <h3>Strings (Cadenas)</h3>
        <p>Las cadenas de Javascript se escriben entre comillas simples o dobles.</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploSix}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <p>Es posible que necesite usar comillas en el texto de su cadena, para eso use comillas diferentes a las que definen la cadena</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploSeven}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />

        <h3>Numbers (Numeros)</h3>
        <p>Los números de Javascript se pueden escribir con o sin decimales.</p>
        <p>los números nunca deben escribirse entre comillas ya que eso convertirá un número en una cadena.</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploEight}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />

        <h3>Booleans (Verdadero o Falso)</h3>
        <p>Booleans solo tienen 2 valores: True(Verdadero) y False(Falso)</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploNine}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>false</p>
              <p>true</p>
            </div>
          </div>
        </Tabs>

        <hr className='style-line' />

        <h3>Arrays (Estructura De Datos)</h3>
        <ul className='lesson-ul'>
          <li>Arrays en javascript se escriben entre corchetes <strong>[ ]</strong></li>
          <li>Los elementos dentro de un array están separados por comas</li>
          <li>El siguiente ejemplo crea un array de "colores" con 3 elementos dentro</li>
        </ul>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploArray}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />

        <h3>Objects (Objeto)</h3>

        <p>Los objetos en javascript se escriben con llaves <strong>&#123; &#125;</strong>.</p>
        <p>El tipo de objeto debe tener una <strong>&#123; propiedad: valor &#125;</strong>, separados por comas.</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploObjeto}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />

        <h3>Undefined (Indefinido)</h3>
        <p>En javascript, un variable sin valor tiene un tipo de valor indefinido.</p>
        <p>Nuestra variable "nombre" es de tipo indefinido ya que no le hemos asignado un valor.</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploObjeto}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />

        <h3>Null</h3>
        <p>En javascript NULL tampoco significa nada, pero la diferencia es que incluso si está vacío, el tipo sigue siendo un objeto.</p>
        <p>Pueden vaciar un objeto estableciendo su valor a <u>null</u>:</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploNull}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <p>También puede vaciar un objeto estableciendo su valor a <u>undefined</u>:</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsEjemploNullTwo}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Datos
