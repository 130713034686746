import React from 'react'
import { Link } from 'react-router-dom'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import comExample from '../images/computer.jpg'
import '../../../sidebar/sidebar.css'

const Enlaces = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Enlaces en HTML</h2>
        <p>Qué es un atributo HTML</p>
        <ul className='lesson-ul'>
          <li>
            Los enlaces se encuentran en casi todas las páginas web. Los enlaces permiten a los usuarios hacer clic de una página a otra
          </li>
          <li>
            Un enlace no tiene que ser texto. Puede ser una imagen o cualquier otro elemento HTML
          </li>
          <li>
            Cuando mueva el mouse sobre un enlace, la flecha del mouse se convertirá en una pequeña mano
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Syntax</h3>
        <ul className='lesson-ul'>
          <li>
            La etiqueta de enlace se definen usando <span className='tag'>&lt;a&gt;<span className='editor_text' />&lt;/a&gt;</span>
          </li>
          <li>
            Cualquier texto, imagen o cualquier elemento HTML dentro de la etiqueta <span className='tag'>&lt;a&gt;<span className='editor_text' />&lt;/a&gt;</span> se convierte en un enlace
          </li>
          <li>
            El <span className='text_editor_attribute'>href</span>=<span className='editor_link_attribute'>""</span> attributo especifica el URL de la página a la que va el enlace
          </li>
        </ul>
        {/* fix href issue */}
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlEnlacesSyntax}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <Link to={{ pathname: 'https://www.hablacode.org' }} target='_blank' rel='noreferrer'>
                <h3>Este es un enlace a hablacode</h3>
              </Link>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Enlace HTML - Atributo de destino</h3>
        <p>Agregando el atributo <span className='tag'><span className='text_editor_attribute'>target</span>="_blank"</span> a un enlace abrirá el enlace en otro pagina en lugar de abrirlo en la página que está actualmente.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlEnlacesTarget}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <Link to={{ pathname: 'https://www.hablacode.org' }} target='_blank' rel='noreferrer'>
                <h3>Este enlace se abrira en otra pagina.</h3>
              </Link>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Enlace HTML - Imagen</h3>
        <p>
          Insertando la etiqueta <span className='tag'>&lt;img <span className='text_editor_attribute'>src</span>=<span className='editor_link_attribute'>""</span>&gt;</span> entre las etiquetas <span className='tag'>&lt;a&gt;<span className='editor_text' />&lt;/a&gt;</span> harás tu imagen en un enlace.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlEnlacesImg}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <Link to={{ pathname: 'https://www.hablacode.org' }} target='_blank' rel='noreferrer'>
                <img src={comExample} alt='ejemplo computadora' />
              </Link>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Enlace HTML - Titulo</h3>
        <p>
          Insertando <span className='tag'><span className='text_editor_attribute'>title</span>=""</span> atributo a tu enlace, permite que los usuario conozcan más información sobre de se trata el enlace.
        </p>
        {/* fix href issue */}
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlEnlacesTitulo}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 href='http://www.hablacode.com' title='Plataforma de aprendizaje'>Habla Code</h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Enlaces
