import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import '../../../sidebar/sidebar.css'

const Bloques = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Bloques y elementos en linea HTML</h2>
        <p>Cómo usar bloques y elementos en línea</p>
        <ul className='lesson-ul'>
          <li>
            <strong>Bloques:</strong> Un elemento de nivel de bloque siempre comienza en una nueva línea y ocupa todo lo ancho disponible
          </li>
          <li>
            <strong>Elemento en linea:</strong> Un elemento en línea no se inicia en una nueva línea y solo ocupa lo ancho que sea necesario
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Elemento bloque</h3>
        <p>
          La etiqueta <code><span className='tag'>&lt;div&gt;&lt;/div&gt;</span></code> es un elemento bloque.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlDiv}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <div>Hola</div>
              <div>Mundo</div>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Elemento en linea</h3>
        <p>
          La etiqueta <code><span className='tag'>&lt;span&gt;&lt;/span&gt;</span></code> es un elemento en linia: es decir, todo estará en la misma línea.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlSpan}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <span>Hola</span>
              <span>Mundo</span>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Bloques
