import React from 'react';

// Define the main colors used in VS Code's theme for CSS
const vscodeTheme = {
    background: '#272727',
    text: '#D4D4D4',
    cssSelector: '#D4D4D4',
    cssProperty: '#9CDCFE',
    cssValue: '#CE9178',
    mediaQuery: '#C586C0',
    comment: '#6A9955',
};

// A component that displays a complex CSS code snippet with syntax highlighting
const CssCodeSnippetComplex = () => {
    const explanationStyle = {
        backgroundColor: vscodeTheme.background,
        color: vscodeTheme.text,
        padding: '20px',
        fontFamily: 'Consolas, "Courier New", monospace',
        borderRadius: '5px',
        marginBottom: '1.5rem',
        borderLeft: '4px solid'
    };

    const codeStyle = {
        ...explanationStyle,
        border: '1px solid #fff',
        marginTop: '10px',
        whiteSpace: 'pre',
        overflowX: 'auto',
        borderLeft: '1px solid #fff'
    };

    const explanation = 'Este ejemplo muestra un fragmento de CSS más complejo con diseño responsivo para dispositivos móviles.';

    return (
        <div className='mb-8'>
            <div style={explanationStyle}>{explanation}</div>
            <pre style={codeStyle}>
                <span style={{ color: vscodeTheme.comment }}>{"/*"} General styles {"*/"}</span><br />
                <span style={{ color: vscodeTheme.cssSelector }}>.container </span>{'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>display</span>: <span style={{ color: vscodeTheme.cssValue }}>flex</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>flex-direction</span>: <span style={{ color: vscodeTheme.cssValue }}>column</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>padding</span>: <span style={{ color: vscodeTheme.cssValue }}>20px</span>;<br />
                {'}'}<br />
                <br />
                <span style={{ color: vscodeTheme.cssSelector }}>.header </span>{'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>background-color</span>: <span style={{ color: vscodeTheme.cssValue }}>#569CD6</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>color</span>: <span style={{ color: vscodeTheme.cssValue }}>white</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>padding</span>: <span style={{ color: vscodeTheme.cssValue }}>10px</span>;<br />
                {'}'}<br />
                <br />
                <span style={{ color: vscodeTheme.mediaQuery }}>@media (max-width: 600px) </span>{'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssSelector }}>.container </span>{'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>flex-direction</span>: <span style={{ color: vscodeTheme.cssValue }}>row</span>;<br />
                &nbsp;&nbsp;{'}'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssSelector }}>.header </span>{'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>background-color</span>: <span style={{ color: vscodeTheme.cssValue }}>#4B6EAF</span>;<br />
                &nbsp;&nbsp;{'}'}
                <br />
                {'}'}
            </pre>
        </div>
    );
};

export default CssCodeSnippetComplex;
