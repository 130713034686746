import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import '../../../sidebar/sidebar.css'
const Tablas = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Tablas en HTML</h2>
        <ul className='lesson-ul'>
          <li>
            La etiqueta para crear una tabla se difine usando la etiqueta <code><span className='tag'>&lt;table&gt;&lt;/table&gt;</span></code>
          </li>
          <li>
            <code><span className='tag'>&lt;tr&gt;&lt;/tr&gt;</span></code> etiqueta es como defines una fila en una tabla
          </li>
          <li>
            <code><span className='tag'>&lt;th&gt;&lt;/th&gt;</span></code> etiqueta es como defines títulos de una tabla
          </li>
          <li>
            <code><span className='tag'>&lt;td&gt;&lt;/td&gt;</span></code> etiqueta es como defines datos de una tabla
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlTablaSyntax}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <table style={{ width: '100%' }}>
                <tr>
                  <th>Nombre</th>
                  <th>Apellido</th>
                </tr>
                <tr>
                  <td>Edgar</td>
                  <td>Patrillo</td>
                </tr>
                <tr>
                  <td>Eva</td>
                  <td>Gonzales</td>
                </tr>
              </table>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Tablas
