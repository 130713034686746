const snippets = {

  // Intro HTML

  htmlIntro: `
<!DOCTYPE html>
<html>
<head>
..
</head>
<body>
..
</body>
</html>
  `.trim(),

  htmlIntroPartTwo: `
<!DOCTYPE html>
<html>
<head>
  <title>Ejemplo de titulo</title>
</head>
<body>
  <h1>Esta es una etiqueta de título</h1>
  <p>Esta etiqueta se usa para indicar párrafos</p>
  <p>Abajo se muestra una lista ordenada:</p>
  <ol>
    <ul>Primer Paso</ul>
    <ul>Segundo Paso</ul>
    <ul>Tercer Paso</ul>
    <ul>Cuarto Paso</ul>
  </ol>
</body>
</html>
  `.trim(),
  // Elementos HTML

  htmlElementsStructura: `
<!DOCTYPE html>
<html>
<head>
  <title>Ejemplo de titulo</title>
</head>
<body>
  <h4>los elementos dentro delas etiquetas <body> contiene el contenido visible de la página</h4>
</body>
</html>
`.trim(),

  htmlElementsEncabezamientos: `
<!DOCTYPE html>
<html>
<head>
  <title>Ejemplo de titulo</title>
</head>
<body>
  <h1>titulo 1</h1>
  <h2>titulo 2</h2>
  <h3>titulo 3</h3>
  <h4>titulo 4</h4>
  <h5>titulo 5</h5>
  <h6>titulo 6</h6>
</body>
</html>
`.trim(),

  parrafos: `
<p>Este es un ejemplo de párrafo usando la etiqueta de párrafo HTML</p>
`.trim(),

  espacios: `
<html>
<head>
</head>
<body>
  <br>
  <br>
  <p>Hay 2 espacios sobre la etiqueta de párrafo</p>
</body>
</html>
`.trim(),

  htmtBotones: `
<button>Click</button>
`.trim(),

  // Attributes HTML

  htmlAtributoSrc: `
<img src="Imagen_de_muestra.jpg">
`.trim(),

  htmlAtributoStyle: `
<h3 style="color:red;">Texto de ejemplo..</h3>
`.trim(),

  htmlAtributoHref: `
<h3 href="www.hablacode.org">HablaCode</h3>
`.trim(),

  htmlAtributoTitle: `
<h3 title="titulo ejemplo...">Bienvenido</h3>
`.trim(),

  htmlAtributoLang: `
<!DOCTYPE html>
<html lang="es">
<head>
</head>
<body>
</body>
</html>
`.trim(),

  // Formateo HTML

  htmtFormateoSubrayado: `
<h3>
  <b>texto mas oscuro</b>
</h3>

<h3>
  texto regular
</h3>
`.trim(),

  htmtFormateoBold: `
<h3>
  <u>texto subrayado</u>
</h3>
`.trim(),

  htmtFormateoEliminado: `
<h3>
  <del>texto aparecerá eliminado</del>
</h3>
`.trim(),

  htmtFormateoCitas: `
<h3>
  El objectivo de Elon Musk es <q>ir a Mars</q>
</h3>
`.trim(),

  htmlComentarios: `
<!DOCTYPE html>
<html>
<head>
  <title>Comentarios</title>
</head>
<body>
  <!-- Este es un comentario y no será visible en el navegador -->
  <h3>Mi comentario no se mostrará</h3>
</body>
</html>
`.trim(),

  // Enlaces en HTML

  htmlEnlacesSyntax: `
<a href="https://www.hablacode.com">Este es un enlace a hablacode</a>
`.trim(),

  htmlEnlacesTarget: `
<a href="https://www.hablacode.com" target="_blank">Este enlace se abrira en otra pagina</a>
`.trim(),

  htmlEnlacesImg: `
<a href="https://www.hablacode.com">
  <img src="mountain_image.jpg">
</a>
`.trim(),

  htmlEnlacesTitulo: `
<a href="https://www.hablacode.com" title="Plataforma de apendizaje">Enlace con titulo</a>
`.trim(),

  // Imágenes en HTML

  htmlImgSyntax: `
<!DOCTYPE html>
<html>
<head>
  <title>Ejemplo de imagen</title>
</head>
<body>
  <img src="cubo_rojo.jpg>
</body>
</html>
`.trim(),

  htmlImgAlt: `
<img src="cubo_rojo.jpg" alt="Un cubo rojo">
`.trim(),

  htmlImgAnchoYAltura: `
<img src="cubo_rojo.jpg" height="100" width="100">
`.trim(),

  htmlImgEnlace: `
<a href="https://www.hablacode.com">
  <img src="mountain_image.jpg">
</a>
`.trim(),

  // Tablas en HTML

  htmlTablaSyntax: `
<table style="width:100%">
<tr>
  <th>Nombre</th>
  <th>Apellido</th>
</tr>
<tr>
  <td>Carlos</td>
  <td>Angelica</td>
</tr>
<tr>
  <td>Joselyn</td>
  <td>Alejando</td>
</tr>
</table>
`.trim(),

  // Listas en HTML

  htmlListasOrden: `
<ol>
  <li>Artículo uno</li>
  <li>Artículo dos</li>
  <li>Artículo tres</li>
</ol>
`.trim(),

  htmlListasSinOrden: `
<ul>
  <li>Pastel</li>
  <li>Galletas</li>
  <li>Dulces</li>
</ul>
`.trim(),

  // Bloques en HTML

  htmlDiv: `
<div>Hola</div>
<div>Mundo</div>
`.trim(),

  htmlSpan: `
<span>Hola</span>
<span>Mundo</span>
`.trim(),

  // CSS con HTML

  htmlCSSLinea: `
<h2 style="background-color:green;color:white;padding:10px;"> Hello World</h2>
<p style="text-align: center;"> Aprende a programmar!</p>
`.trim(),

  htmlCSSInterno: `
<!DOCTYPE html>
<html>
<head>
<style>
h2 {  
 background-color:green;
 color: white;
 padding:10px;    
} 
p {  
 text-align: center;    
} 
</style> 
</head>
        
<body>
 <h2>Hola Mundo</h2>
 <p>Aprende a programmar! </hp>
</body>
</html>
`.trim(),

  htmlCSSExterno: `
<!DOCTYPE html>
<html>
<head>
<link rel="stylesheet" href="estilo.css">
</head>
        
<body>
 <h2>Hola Mundo</h2>
 <p>Aprende a programmar! </hp>
</body>
</html>
`.trim(),

  htmlCSSExternoStyle: `
h2 {  
  background-color:green;
  color:blanco;
  padding:10px;    
 }

 p {  
  text-align:center;    
 } 
`.trim(),

  // Classes & id HTML

  atributoClase: `
<style> 
  .texto_pricipal {  
    background-color: red;
    color: white;
    text-align: center;
   }
</style> 
          
<body>
  <div class="texto_principal">
    <h4>Ejemplo 1</h4>
    <p>Estos elementos html tienen el mismo nombre de clase, lo que significa que ambos tienen el mismo estilo.</p>
  </div>
  
  <div class="texto_principal">
    <h4>Ejemplo 2</h4>
    <p>Estos elementos html tienen el mismo nombre de clase, lo que significa que ambos tienen el mismo estilo..</p>
   </div>
</body>
`.trim(),

  atributoid: `
<style> 
  #texto_pricipal {  
    background-color: red;
    color: white;
    padding: 45px;
    text-align: center;
   }
</style> 
          
<body>
  <h3 id="texto">Coche</h3>
  <p>Este es un párrafo de muestra</p>
</body>
`.trim(),

  // JS con HTML

  htmlJSInterno: `
<!DOCTYPE html>
<html>
<body>
 <h2>Hola Mundo</h2>
<script>
 alert=('Hola Mundo') 
</script>
</body>
</html>
`.trim(),

  htmlJSExterno: `
<!DOCTYPE html>
<html>
<head>
</head>
    
<body>
  <button type="button" onClick="miFuncion">Intentalo</button>

  <script src="script.js"></script>
</body>
</html>
`.trim(),

  htmlJSExternoJS: `
function miFuncion() {
  alert("Soy una alerta!");
`.trim()

}

export default snippets
