import React from 'react';

// Define the main colors used in VS Code's theme for CSS
const vscodeTheme = {
    background: '#272727',
    text: '#D4D4D4',
    cssSelector: '#D4D4D4',
    cssPseudo: '#C586C0',
    cssProperty: '#9CDCFE',
    cssValue: '#CE9178',
    comment: '#6A9955',
};

// A component that displays a complex CSS code snippet with syntax highlighting
const CssCodeSnippetComplexVersion = () => {
    const explanationStyle = {
        backgroundColor: vscodeTheme.background,
        color: vscodeTheme.text,
        padding: '20px',
        fontFamily: 'Consolas, "Courier New", monospace',
        borderRadius: '5px',
        marginBottom: '1.5rem',
        borderLeft: '4px solid'
    };

    const codeStyle = {
        ...explanationStyle,
        border: '1px solid #fff',
        marginTop: '10px',
        whiteSpace: 'pre',
        overflowX: 'auto',
        borderLeft: '1px solid #fff'
    };

    const explanation = 'Este ejemplo muestra un fragmento de CSS complejo con efectos de desplazamiento y pseudoelementos para un estilo más complejo.';

    return (
        <div className='mb-8'>
            <div style={explanationStyle}>{explanation}</div>
            <pre style={codeStyle}>
                <span style={{ color: vscodeTheme.comment }}>{"*/"} Button styling with hover effect {"*/"}</span><br />
                <span style={{ color: vscodeTheme.cssSelector }}>button </span>{'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>background-color</span>: <span style={{ color: vscodeTheme.cssValue }}>#4CAF50</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>color</span>: <span style={{ color: vscodeTheme.cssValue }}>white</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>padding</span>: <span style={{ color: vscodeTheme.cssValue }}>15px 32px</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>text-align</span>: <span style={{ color: vscodeTheme.cssValue }}>center</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>text-decoration</span>: <span style={{ color: vscodeTheme.cssValue }}>none</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>display</span>: <span style={{ color: vscodeTheme.cssValue }}>inline-block</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>font-size</span>: <span style={{ color: vscodeTheme.cssValue }}>16px</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>margin</span>: <span style={{ color: vscodeTheme.cssValue }}>4px 2px</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>cursor</span>: <span style={{ color: vscodeTheme.cssValue }}>pointer</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>border-radius</span>: <span style={{ color: vscodeTheme.cssValue }}>4px</span>;<br />
                {'}'}<br />
                <span style={{ color: vscodeTheme.cssSelector }}>button:hover </span>{'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>background-color</span>: <span style={{ color: vscodeTheme.cssValue }}>#45a049</span>;<br />
                {'}'}<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"/*"} Pseudo-element styling for ::before and ::after {"*/"}</span><br />
                <span style={{ color: vscodeTheme.cssSelector }}>button::before, button::after </span>{'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>content</span>: <span style={{ color: vscodeTheme.cssValue }}>"▶"</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>color</span>: <span style={{ color: vscodeTheme.cssValue }}>red</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>padding-right</span>: <span style={{ color: vscodeTheme.cssValue }}>5px</span>;<br />
                {'}'}<br />
                <span style={{ color: vscodeTheme.cssSelector }}>button::after </span>{'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>content</span>: <span style={{ color: vscodeTheme.cssValue }}>"◀"</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>color</span>: <span style={{ color: vscodeTheme.cssValue }}>blue</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>padding-left</span>: <span style={{ color: vscodeTheme.cssValue }}>5px</span>;<br />
                {'}'}<br />
            </pre>
        </div>
    );
};

export default CssCodeSnippetComplexVersion;
