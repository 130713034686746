import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'
import '../../../tabs/tab.css'

const Operadores = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Operadores Javascript</h2>
        <p>Hemos utilizado un par de operadores en javascript en las lecciones anteriores.</p>
        <p>Utilizamos el (=) para asignar valores, los operadores aritméticos (+ - / *) para sumar, restar, multiplicar y dividir.</p>
        <p>Hay más operadores y en esta lección los repasaremos.</p>
        <ul className='lesson-ul'>
          <li>Operadores aritméticos</li>
          <li>Operadores de Asignación</li>
          <li>Operadores de comparación</li>
          <li>Operadores logicos</li>
        </ul>
        <hr className='style-line' />
        <h3>Operadores Aritméticos</h3>
        <p>Se utilizan para realizar operaciones matemáticas.</p>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope='col'>Operadores</th>
              <th scope='col'>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>+</td>
              <td>Adición</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Sustracción</td>
            </tr>
            <tr>
              <td>*</td>
              <td>Multiplicación</td>
            </tr>
            <tr>
              <td>/</td>
              <td>División</td>
            </tr>
            <tr>
              <td>%</td>
              <td>Operador de módulo. Devuelve el resto de dos operandos</td>
            </tr>
            <tr>
              <td>++</td>
              <td>Incremento</td>
            </tr>
            <tr>
              <td>--</td>
              <td>Decremento</td>
            </tr>
          </tbody>
        </table>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsOperadoresAritmeticos}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>35</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Operadores De Asignación</h3>
        <p>Se utilizan para asignar un valor a su operando izquierdo basándose en el valor de su operando derecho.</p>
        <p><strong>Ejemplo</strong>: La expresión 5 * 6, el * es el operador. El 5 y el 6 son los operandos, y 30 es el resultado.</p>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope='col'>Operadores</th>
              <th scope='col'>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>+</td>
              <td>Adición</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Sustracción</td>
            </tr>
            <tr>
              <td>*</td>
              <td>Multiplicación</td>
            </tr>
            <tr>
              <td>/</td>
              <td>División</td>
            </tr>
            <tr>
              <td>%</td>
              <td>Operador de módulo. Devuelve el resto de dos operandos</td>
            </tr>
            <tr>
              <td>++</td>
              <td>Incremento</td>
            </tr>
            <tr>
              <td>--</td>
              <td>Decremento</td>
            </tr>
          </tbody>
        </table>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsOperadoresAsignacion}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>50</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Operadores De Cadena(string)</h3>
        <p>El operador ( + ) también se puede usar para agregar cadenas(strings), esto se llama concatenar cadenas(strings) en javascript.</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsOperadoresCadena}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>Juan Doe</p>
            </div>
          </div>
        </Tabs>
        <p>El operador ( += ) también se puede usar para concatenar cadenas, esto reducirá la cantidad de escritura.</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsOperadoresCadenaEjemplo2}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>Buenos días a todos</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Operadores De Comparación</h3>
        <p>Javascript se utilizan para comparar valores y devolver verdadero(<strong>true</strong>) o(<strong>or</strong>) falso(<strong>false</strong>).</p>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope='col'>Operadores</th>
              <th scope='col'>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>==</td>
              <td>igual a</td>
            </tr>
            <tr>
              <td>===</td>
              <td>igual a valor y igual tipo</td>
            </tr>
            <tr>
              <td>!=</td>
              <td>no es igual</td>
            </tr>
            <tr>
              <td>!==</td>
              <td>no igual valor o no igual tipo</td>
            </tr>
            <tr>
              <td>&gt;</td>
              <td>Mayor que</td>
            </tr>
            <tr>
              <td>&lt;</td>
              <td>Menor que</td>
            </tr>
            <tr>
              <td>&gt;=</td>
              <td>Mayor o igual que</td>
            </tr>
            <tr>
              <td>&lt;=</td>
              <td>Menor o igual que</td>
            </tr>
            <tr>
              <td>?</td>
              <td>operador ternario</td>
            </tr>
          </tbody>
        </table>
        <p><strong>Operadores de Comparación ejemplo:</strong></p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsOperadoresCompar}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>false // 10 no es menos que 9</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Operadores Logicos</h3>
        <p>Javascript se utilizan para determinar la lógica entre variables o valores. El resultado se mostrará como verdadero(<strong>true</strong>) o falso(<strong>false</strong>).</p>
        <ul className='lesson-ul'>
          <li><strong>&&</strong> devuelve verdadero(true) solo si ambas declaraciones son correctas.</li>
          <li><strong>||</strong> devuelve verdadero(true) si una o ambas declaracison son correctas. De lo contrario, devuelve falso(false).</li>
          <li><strong>!</strong>  devuelve verdadero(true) para declaraciones falsas y falso(false) para declaraciones verdaderas.</li>
        </ul>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope='col'>Operadores</th>
              <th scope='col'>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&&</td>
              <td>Y (AND)</td>
            </tr>
            <tr>
              <td>||</td>
              <td>O (OR)</td>
            </tr>
            <tr>
              <td>!</td>
              <td>NO (NOT)</td>
            </tr>
          </tbody>
        </table>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Operadores
