import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import hablacode from "../../Images/logo.svg";
import { IoLogoHtml5 } from "react-icons/io";
import { FaCss3Alt } from "react-icons/fa";
import { DiJavascript } from "react-icons/di";

const Navbar = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentLesson, setCurrentLesson] = useState({
    icon: null,
    iconColor: "",
  });
  const location = useLocation();
  const dropdownRef = useRef(null);
  const isHomePage = location.pathname === "/";

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  const dropdownButtonRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        dropdownButtonRef.current &&
        !dropdownButtonRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleDocumentClick);

    if (location.pathname.startsWith("/html")) {
      setCurrentLesson({ icon: IoLogoHtml5, iconColor: "#e34c26" });
    } else if (location.pathname.startsWith("/css")) {
      setCurrentLesson({ icon: FaCss3Alt, iconColor: "#264de4" });
    } else if (location.pathname.startsWith("/javascript")) {
      setCurrentLesson({ icon: DiJavascript, iconColor: "#F0DB4F" });
    } else {
      setCurrentLesson({ icon: null, iconColor: "" });
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [location.pathname]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectLesson = (iconComponent, iconColor) => {
    setCurrentLesson({ icon: iconComponent, iconColor: iconColor });
    setDropdownOpen(false);
  };

  return (
    <div className="fixed top-0 left-0 w-full bg-white z-50 backdrop-filter backdrop-blur-md bg-opacity-0 border-b border-gray-200">
      <nav className="flex items-center justify-between p-2 md:ml-36 md:mr-36">
        <div className="flex items-start">
          <button
            onClick={props.drawerClickHandler}
            className="md:hidden block p-2 focus:outline-none"
          >
            <svg
              className={`h-6 w-6 ${
                isHomePage || isScrolled ? "text-white" : "text-black"
              } md:filter-none`}
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
          <NavLink
            to="/"
            exact
            rel="preload"
            className="order-last md:order-first ml-3 pt-1 md:ml-0"
          >
            <img src={hablacode} alt="hablacode Logo" className="h-8 w-auto" />
          </NavLink>
        </div>
        <ul className="hidden md:flex space-x-4 items-center">
          <li>
            <NavLink
              exact
              to="/"
              className="text-gray-700 hover:text-white-900 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200"
              activeClassName="bg-gray-200 text-gray-900"
            >
              Inicio
            </NavLink>
          </li>
          <li className="relative" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              ref={dropdownButtonRef}
              className={`text-gray-700 hover:text-gray-900 focus:text-gray-900 px-2 py-2 rounded-md text-sm font-medium flex items-center ${
                dropdownOpen ? "bg-gray-200" : "hover:bg-gray-200 "
              }`}
            >
              Desarollo Web
              {currentLesson.icon && (
                <span className="ml-2">
                  <currentLesson.icon
                    style={{ color: currentLesson.iconColor }}
                  />
                </span>
              )}
              <svg
                className={`ml-2 w-4 h-4 transform transition-transform duration-200 ${
                  dropdownOpen ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {dropdownOpen && (
              <ul className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md py-1 z-50">
                <li onClick={() => selectLesson(IoLogoHtml5)}>
                  <NavLink
                    to="/html"
                    className="flex items-center space-x-2 px-4 py-2 text-sm text-whit hover:bg-gray-100"
                    activeClassName="bg-gray-200 text-gray-900"
                  >
                    <IoLogoHtml5 style={{ color: "#e34c26" }} />
                    <span>HTML</span>
                  </NavLink>
                </li>
                <li onClick={() => selectLesson(FaCss3Alt)}>
                  <NavLink
                    to="/css"
                    className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    activeClassName="bg-gray-200 text-gray-900"
                  >
                    <FaCss3Alt style={{ color: "#264de4" }} />
                    <span>CSS</span>
                  </NavLink>
                </li>
                <li onClick={() => selectLesson(DiJavascript)}>
                  <NavLink
                    to="/javascript"
                    className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    activeClassName="bg-gray-200 text-gray-900"
                  >
                    <DiJavascript style={{ color: "#F0DB4F" }} />
                    <span>JavaScript</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/"
                    className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <FaReact style={{ color: "#242443" }} />
                    <span>React</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/"
                    className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <FaWix />
                    <span>Wix</span>
                  </NavLink>
                </li> */}
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
