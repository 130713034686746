import React from "react";
import "./Land.css";

const Landing = () => {
  return (
    <div className="">
      <div className="bg-stone-900 min-h-screen flex items-center justify-center p-4">
        <div className="">
          <h1 className="slide-in text-6xl mt-2 py-6 md:text-7xl font-bold bg-hablacode-gradient  text-transparent bg-clip-text align-middle">
            <div className="block mb-4">Aprende</div>
            <div className="block mb-4">Desarollo Web</div>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Landing;
