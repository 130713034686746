import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'
import '../../../tabs/tab.css'

const Mostrar = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Mostrar Javascript</h2>
        <p>Javascript te da la posibilidad de mostrar datos de diferentes manera</p>
        <ul className='lesson-ul'>
          <li>
            window.alert()
          </li>
          <li>
            console.log()
          </li>
          <li>
            innerHTML
          </li>
        </ul>
        <hr className='style-line' />
        <h3>window.alert()</h3>
        <p><strong>La alerta de ventana</strong> es un método utilizado para mostrar una alarma con la opción de agregar cualquier contenido de su elección.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsWindow}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>console.log()</h3>
        <p>El método <strong>console.log</strong> envía información de su elección a la consola web. Podría ser un string ejemplo:"error" o cualquier objeto.</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsConsole}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>16</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>innerHTML</h3>
        <p><strong>document.getElementById(nombre de ID)</strong> se usa para acceder a elementos html en javascript.</p>
        <p>El ID define el attributo de HTML. La propiedad <strong>innerHTML</strong> define el elemento HTML.</p>
        <p>Ejemplo: Tenemos una etiqueta &lt;h4&gt; con un <strong>id="(demo)"</strong> y un controlador de eventos de <strong>onclick="ejemplo()"</strong> con un nombre de función "ejemplo", nuestra función seleccionará nuestro id y lo cambiará al nuevo texto una vez que haga click en nuestro texto anterior.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsInnerHtml}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Mostrar
