import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const Texto = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Texto CSS</h2>
        <p>A continuación, encontrarás ejemplos de cómo personalizar su texto a su gusto utilizando las siguientes propiedades:</p>
        <ul className='lesson-ul'>
          <li>
            color
          </li>
          <li>
            text-align
          </li>
          <li>
            text-decoration
          </li>
          <li>
            text-transform
          </li>
          <li>
            letter-spacing
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Text color</h3>
        <p>La propiedad <strong>color</strong> se utiliza para colorear texto</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssTextColor}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 className='text-color-h3'>Hola Mundo</h3>
              <p className='text-color-p'>Este texto tiene color azul </p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>text-align</h3>
        <p>La propiedad <strong>text-align</strong> se usa para alinear el texto en el centro, a la izquierda o a la derecha.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssTextAlign}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 className='text-color-h3-align'>Hola Mundo</h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>text-decoration</h3>
        <p>La propiedad <strong>text-decoration</strong> se usa para añadir decoración al texto.</p>
        <p>También tiene la opción de eliminar la decoración de un texto usando la propiedad "<strong>text-decoration: none;</strong>"</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssTextDecoration}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h1 className='text-color-dec-h1'>Titulo 1</h1>
              <h2 className='text-color-dec-h2'>Titulo 2</h2>
              <h3 className='text-color-dec-h3'>Titulo 3</h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>text-transform</h3>
        <p>La propiedad <strong>text-transform</strong> se usa para añadir mayúscula, minúscula, o para ser un texto en mayúscula.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssTextTransform}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h1 className='text-color-transform-h1'>todo el texto en mayúsculas</h1>
              <h2 className='text-color-transform-h2'>Texto En Minúscula</h2>
              <h3 className='text-color-transform-h3'>texto mayúscula</h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>letter-spacing</h3>
        <p>La propiedad <strong>letter-spacing</strong> se utiliza para aumentar o disminuir espacio entre las palabras.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssTextSpacing}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 className='text-color-spacing-h3'>Aumente el espacio de las palabras en este titulo</h3>
              <p className='text-color-spacing-p'>Disminuyo el espacio de las palabras en este párrafo</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Texto
