import React, { useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Link,
  Redirect,
} from "react-router-dom";
import TitleSetter from "../../hoc/TitleSetter";

import { RiArrowDropUpFill } from "react-icons/ri";
import { IoLogoHtml5 } from "react-icons/io";
import Comments from "./tutorials/Comments";
import Elementos from "./tutorials/Elementos";
import Attributes from "./tutorials/Attributes";
import Formatting from "./tutorials/Formatting";
import Enlaces from "./tutorials/Enlaces";
import Imagenes from "./tutorials/Imagenes";
import Tablas from "./tutorials/Tablas";
import Listas from "./tutorials/Listas";
import Bloques from "./tutorials/Bloques";
import ClaseId from "./tutorials/ClaseId";
import HtmlCss from "./tutorials/HtmlCss";
import HtmlJs from "./tutorials/HtmlJs";
import Nuevo from "./tutorials/Nuevo";
import PageNotFound from "../../../PageNotFound";
import "../../sidebar/sidebar.css";

const normalStyle = {
  display: "inline-block",
  transition: "1s, transform 1s",
};

const transformedStyle = {
  ...normalStyle,
  transform: "rotate(180deg)",
};

const HTML = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/html/");

  const [isActive, setActive] = useState("false");
  const [iconTransition, setIconTransition] = useState(false);

  const handleToggleAndClick = () => {
    setActive(!isActive);
    setIconTransition(!iconTransition);
  };

  const handleLinkClick = () => {
    setActive("false");
    setIconTransition(false);
  };

  return (
    <div className="side-nav-div text-neutral-200">
      <div className="toggle-button-lesson">
        <div className="toggle-con mb-4">
          <button className="lesson-button" onClick={handleToggleAndClick}>
            html / {splitLocation}
            <div style={iconTransition ? transformedStyle : normalStyle}>
              <RiArrowDropUpFill size={28} />
            </div>
          </button>
        </div>
      </div>
      <div className={`${isActive ? "sidenav" : "activeSideNav"}`}>
        <IoLogoHtml5 style={{ color: "#e34c26" }} />
        <div className="list-wrapper">
          <div className="red-line" />

          <div className="list-item-wrapper">
            <Link to={`${path}/introducción-a-html`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "introducción-a-html"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "introducción-a-html"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Introducción a HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-elementos`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-elementos"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-elementos"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Elementos HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-atributos`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-atributos"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-atributos"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Atributos HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-formateo`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-formateo"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-formateo"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Formateo HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-comentarios`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-comentarios"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-comentarios"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Comentarios HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-enlaces`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-enlaces"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-enlaces"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Enlaces HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-imagenes`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-imagenes"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-imagenes"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Imágenes HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-tablas`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-tablas"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-tablas"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Tablas HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-listas`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-listas"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-listas"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Listas HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-bloques`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-bloques"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-bloques"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Bloques HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-clases-y-id`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-clases-y-id"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-clases-y-id"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Clases y id HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-y-css`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-y-css"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-y-css"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  CSS y HTML
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/html-y-javascript`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "html-y-javascript"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "html-y-javascript"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Javascript y HTML
                </span>
                <div className="list-text" />
              </div>
              <div className="white-line" />
            </Link>
          </div>
        </div>
      </div>
      <Switch>
        <Route
          exact
          path={path}
          render={() => <Redirect to={`${path}/introducción-a-html`} />}
        />
        <Route path={`${path}/introducción-a-html`}>
          <TitleSetter title="Introduccion a HTML">
            <Nuevo/>
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-elementos`}>
          <TitleSetter title="Elementos en HTML">
            <Elementos />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-atributos`}>
          <TitleSetter title="Atributos en HTML">
            <Attributes />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-formateo`}>
          <TitleSetter title="Formateo en HTML">
            <Formatting />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-comentarios`}>
          <TitleSetter title="Comentarios en HTML">
            <Comments />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-enlaces`}>
          <TitleSetter title="Enlaces en HTML">
            <Enlaces />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-imagenes`}>
          <TitleSetter title="Imágenes en HTML">
            <Imagenes />       
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-tablas`}>
          <TitleSetter title="Tablas en HTML">
            <Tablas />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-listas`}>
          <TitleSetter title="Listas en HTML">
            <Listas />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-bloques`}>
          <TitleSetter title="Bloques en HTML">
            <Bloques />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-clases-y-id`}>
          <TitleSetter title="Clases y id en HTML">
            <ClaseId />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-y-css`}>
          <TitleSetter title="Cómo usar CSS con HTML">
            <HtmlCss />
          </TitleSetter>
        </Route>
        <Route path={`${path}/html-y-javascript`}>
          <TitleSetter title="Cómo usar JavaScript con HTML">
            <HtmlJs />
          </TitleSetter>
        </Route>
        <Route path={`${path}/*`}>
          <TitleSetter title="Page Not Found">
            <PageNotFound />
          </TitleSetter>
        </Route>
      </Switch>
    </div>
  );
};

export default HTML;
