import React from 'react';

// Define the main colors used in VS Code's theme for JavaScript
const vscodeTheme = {
    background: '#272727',
    text: '#D4D4D4',
    jsKeyword: '#C586C0',
    jsFunction: '#DCDCAA',
    jsString: '#CE9178',
    jsNumber: '#B5CEA8',
    jsOperator: '#D4D4D4',
    comment: '#6A9955',
};

// A component that displays a JavaScript code snippet with syntax highlighting
const JavaScriptCodeSnippetComponent = () => {
    const explanationStyle = {
        backgroundColor: vscodeTheme.background,
        color: vscodeTheme.text,
        padding: '20px',
        fontFamily: 'Consolas, "Courier New", monospace',
        borderRadius: '5px',
        marginBottom: '1.5rem',
        borderLeft: '4px solid'
    };

    const codeStyle = {
        ...explanationStyle,
        border: '1px solid #fff',
        marginTop: '10px',
        whiteSpace: 'pre',
        overflowX: 'auto',
        borderLeft: '1px solid #fff'
    };

    const explanation = 'Este ejemplo demuestra una función de JavaScript simple con elementos de sintaxis básicos.';

    return (
        <div className='mb-8'>
            <div style={explanationStyle}>{explanation}</div>
            <pre style={codeStyle}>
                <span style={{ color: vscodeTheme.comment }}>{"//"} Function to calculate sum of numbers</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>function</span> <span style={{ color: vscodeTheme.jsFunction }}>calculateSum</span>(<span style={{ color: vscodeTheme.jsNumber }}>numbers</span>) {'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.jsKeyword }}>let</span> <span style={{ color: vscodeTheme.jsFunction }}>sum</span> <span style={{ color: vscodeTheme.jsOperator }}>=</span> <span style={{ color: vscodeTheme.jsNumber }}>0</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.jsKeyword }}>for</span> (<span style={{ color: vscodeTheme.jsKeyword }}>let</span> <span style={{ color: vscodeTheme.jsFunction }}>i</span> <span style={{ color: vscodeTheme.jsOperator }}>=</span> <span style={{ color: vscodeTheme.jsNumber }}>0</span>; <span style={{ color: vscodeTheme.jsFunction }}>i</span> <span style={{ color: vscodeTheme.jsOperator }}>&lt;</span> <span style={{ color: vscodeTheme.jsFunction }}>numbers</span>.length; <span style={{ color: vscodeTheme.jsFunction }}>i</span><span style={{ color: vscodeTheme.jsOperator }}>++</span>) {'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: vscodeTheme.jsFunction }}>sum</span> <span style={{ color: vscodeTheme.jsOperator }}>+=</span> <span style={{ color: vscodeTheme.jsFunction }}>numbers</span>[<span style={{ color: vscodeTheme.jsFunction }}>i</span>];<br />
                &nbsp;&nbsp;{'}'}<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.jsKeyword }}>return</span> <span style={{ color: vscodeTheme.jsFunction }}>sum</span>;<br />
                {'}'}<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"//"} Example usage</span><br />
                <span style={{ color: vscodeTheme.jsFunction }}>console</span>.<span style={{ color: vscodeTheme.jsFunction }}>log</span>(<span style={{ color: vscodeTheme.jsFunction }}>calculateSum</span>([<span style={{ color: vscodeTheme.jsNumber }}>1</span>, <span style={{ color: vscodeTheme.jsNumber }}>2</span>, <span style={{ color: vscodeTheme.jsNumber }}>3</span>, <span style={{ color: vscodeTheme.jsNumber }}>4</span>]));<br />
            </pre>
        </div>
    );
};

export default JavaScriptCodeSnippetComponent;
