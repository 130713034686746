import React from 'react'
import snippet from '../codeSamples'
import Tabs from '../../../tabs/Tabs'
import '../../../sidebar/sidebar.css'

const ClaseId = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Atributo clase y id HTML</h2>
        <p>Cómo usar el atributo clase y id</p>
        <ul className='lesson-ul'>
          <li>
            clase: tributo se utiliza para definir estilos iguales para elementos con el mismo nombre de clase
          </li>
          <li>
            id atributo se utiliza un id única para un elemento HTML
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Atributo Clase</h3>
        <ul className='lesson-ul'>
          <li>
            El clase selector es para atribuir a todos los elementos de un HTML para luego aplicar estilos a los elementos marcados con esa clase usando hojas de estilo
          </li>
          <li>
            Tiene que tener un nombre, asegúrese de que el nombre sea único
          </li>
          <li>
            La forma de acceder a una clase en una hoja de estilo es escribiendo un punto (.) Y siguiéndola con el nombre de la clase
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.atributoClase}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='texto-pricipal'>
              <h4>Ejemplo 1</h4>
              <p>Estos elementos html tienen el mismo nombre de clase, lo que significa que ambos tienen el mismo estilo.</p>
            </div>

            <div className='texto-pricipal'>
              <h4>Ejemplo 2</h4>
              <p>Estos elementos html tienen el mismo nombre de clase, lo que significa que ambos tienen el mismo estilo..</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Atributo id</h3>
        <ul className='lesson-ul'>
          <li>
            El atributo id especifica una identificación única para un elemento HTML (el valor debe ser único dentro del elemento HTML)
          </li>
          <li>
            En CSS, para seleccionar un elemento con un ID específico, inserta un hash (#), seguido del ID del elemento
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.atributoid}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <h3 id='texto'>Coche</h3>
            <p>Este es un párrafo de muestra</p>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default ClaseId
