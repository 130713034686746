import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import '../../../sidebar/sidebar.css'

const HtmlCss = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        {/* FIX ME */}
        <h2>Cómo usar CSS con HTML</h2>
        <p>Introducción al como usar CSS con HTML</p>
        <ul className='lesson-ul'>
          <li>
            CSS significa "Cascading Style Sheets"
          </li>
          <li>
            CSS se utiliza para el estilo de elementos HTML
          </li>
        </ul>
        <p>Hay 3 formas de CSS a tu proyecto HTML</p>
        <ul className='lesson-ul'>
          <li>
            En linea estilo
          </li>
          <li>
            Interno
          </li>
          <li>
            Externo
          </li>
        </ul>
        <hr className='style-line' />
        <h3>En linea CSS</h3>
        <p>
          En linea estilo - usando el attributo <code><span className='tag'>&lt;style&gt;</span></code> en tus elementos HTML, como lo hemos hecho en una <a href='attributes.html'><u>lección anterior</u></a>.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlCSSLinea}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h2 className='enLinea'> Hello World</h2>
              <p className='enLineaP'> Aprende a programmar!</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Interno CSS</h3>
        <p>
          Interno - se utiliza un CSS interno para definir un estilo para una sola página HTML.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlCSSInterno}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h2 className='enLinea'> Hello World</h2>
              <p className='enLineaP'> Aprende a programmar!</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Externo CSS</h3>
        <ul className='lesson-ul'>
          <li>
            Mediante el uso de un archivo CSS externo
          </li>
          <li>
            Con una hoja de estilo externa, puede cambiar el aspecto de un sitio web completo, ¡cambiando un archivo!
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlCSSExterno}
                </code>
              </pre>
            </div>
          </div>
          <div label='style.css'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlCSSExternoStyle}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h2 className='enLinea'> Hello World</h2>
              <p className='enLineaP'> Aprende a programmar!</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default HtmlCss
