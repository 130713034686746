import React from 'react';

const vscodeTheme = {
    background: '#272727',
    text: '#D4D4D4',
    tag: '#569CD6',
    attributeName: '#9CDCFE',
    attributeValue: '#CE9178',
    comment: '#6A9955',
};

const HtmlCodeSnippetComponent = () => {
    const explanationStyle = {
        backgroundColor: vscodeTheme.background,
        color: vscodeTheme.text,
        padding: '20px',
        fontFamily: 'Consolas, "Courier New", monospace',
        borderRadius: '5px',
        marginBottom: '1.5rem',
        borderLeft: '4px solid'
    };

    const codeStyle = {
        ...explanationStyle,
        border: '1px solid #fff',
        marginTop: '10px',
        whiteSpace: 'pre',
        overflowX: 'auto',
        borderLeft: '1px solid #fff'
    };

    const explanation = 'Este ejemplo muestra un fragmento HTML complejo con varios elementos de sintaxis.';

    return (
        <div className='mb-8'>
            <div style={explanationStyle}>{explanation}</div>
            <pre style={codeStyle}>
                <span style={{ color: vscodeTheme.comment }}>&lt;!-- Main container --&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;div</span> <span style={{ color: vscodeTheme.attributeName }}>class</span><span style={{ color: vscodeTheme.attributeValue }}>=</span><span style={{ color: vscodeTheme.attributeValue }}>"container"</span><span style={{ color: vscodeTheme.tag }}>&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;h1</span> <span style={{ color: vscodeTheme.attributeName }}>id</span><span style={{ color: vscodeTheme.attributeValue }}>=</span><span style={{ color: vscodeTheme.attributeValue }}>"title"</span><span style={{ color: vscodeTheme.tag }}>&gt;</span>Welcome to My Page!<span style={{ color: vscodeTheme.tag }}>&lt;/h1&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;p</span><span style={{ color: vscodeTheme.tag }}>&gt;</span>This is a sample paragraph.<span style={{ color: vscodeTheme.tag }}>&lt;/p&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;!-- Sample list --&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;ul</span><span style={{ color: vscodeTheme.tag }}>&gt;</span><br />
                &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;li</span><span style={{ color: vscodeTheme.tag }}>&gt;</span>Item 1<span style={{ color: vscodeTheme.tag }}>&lt;/li&gt;</span><br />
                &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;li</span><span style={{ color: vscodeTheme.tag }}>&gt;</span>Item 2<span style={{ color: vscodeTheme.tag }}>&lt;/li&gt;</span><br />
                &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;li</span><span style={{ color: vscodeTheme.tag }}>&gt;</span>Item 3<span style={{ color: vscodeTheme.tag }}>&lt;/li&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;/ul&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;/div&gt;</span>
            </pre>
        </div>
    );
};

export default HtmlCodeSnippetComponent;
