import React, { useEffect } from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'
import '../../../tabs/tab.css'

const Comentarios = () => {
  useEffect(() => {
    document.title = 'Comentarios en CSS'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Cómo usar Colores en CSS</h2>
        <p>Cómo / por qué usar comentarios en CSS</p>
        <ul className='lesson-ul'>
          <li>
            Los comentarios le permiten escribir una descripción para comprender por qué agregó / eliminó o necesita agregar algo a tu applicacion
          </li>
          <li>
            Comentarios ayudan a las personas con las que trabaja a entender su código
          </li>
          <li>
            Les permite ingresar notas en CSS que no serán interpretadas
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Etiqueta de comentarios</h3>
        <p>Esta es la etiqueta para agregar comentarios a un CSS archivo.</p>

        <Tabs className='text-editor-tab'>
          <div label='style.css'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssComments}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <br />
        {/* example */}
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssCommentsHtml}
                </code>
              </pre>
            </div>
          </div>
          <div label='estilo.css'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssComentarios}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 style={{ color: 'green' }}>Ejemplo usando el selector css </h3>
            </div>
          </div>
        </Tabs>
        <p>La sintaxis de comentario / * * / se usa para comentarios de una linea y multilínea</p>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Comentarios
