import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const Mostrar = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Display CSS</h2>
        <p>La propiedad <strong>display</strong></p>
        <p>Es la propiedad de CSS más importante para controlar estructuras.</p>
        <p>Todos los elementos HTML tienen un valor de display predeterminado, dependiendo en el tipo de elemento.</p>
        <p>La propiedad <strong>display</strong> nos permite determinar cómo se mostrará la caja, mostrar como oculta o mostrar.</p>
        <p>Hay varios valores de la propiedad de <strong>display</strong>. Estaré cubriendo las siguientes partes en esta lección:</p>
        <ul className='lesson-ul'>
          <li>
            display: block;
          </li>
          <li>
            display: inline;
          </li>
          <li>
            display: none;
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Block-level</h3>
        <p>Un elemento <strong>block-level</strong> comienza en una nueva línea y se estira hasta la derecha y izquierda tan lejos como pueda.</p>
        <p>Lista de algunos elementos block-level:</p>
        <ul className='lesson-ul'>
          <li>
            h1, h2, h3, h4, h5, h6
          </li>
          <li>
            address, p
          </li>
          <li>
            blockquote
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssDisplayBlock}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <div className='display-block-level'>Un <strong>div</strong> es un elemento block-level</div>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>inline</h3>
        <p>Un elemento <strong>inline</strong> se utiliza para agrupar y aplicar estilos a elementos en línea.</p>
        <p>Un elemento inline no comienza en una nueva línea y puede usarse, por ejemplo, dentro de una etiqueta de párrafo sin interrumpir el flujo.</p>
        <p>Lista de algunos elementos inline:</p>
        <ul className='lesson-ul'>
          <li>
            button, input, label, select, textarea
          </li>
          <li>
            b, big, small,
          </li>
          <li>
            strong, img, em
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssDisplayInline}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <div>Esto es un párrafo, <span className='display-block-level'>span es un elemento en línea</span></div>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>none</h3>
        <p>display: <strong>none;</strong></p>
        <p>elimina el elemento completamente del documento. No ocupa ningún espacio, a pesar de que el HTML para él todavía está en el código fuente.</p>
        <p>visibilty: <strong>hidden;</strong></p>
        <p>Se puede usar para ocultar elementos pero ocupará el espacio que necesita.</p>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Mostrar
