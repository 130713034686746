import React, { useEffect } from 'react'
import modeloCaja from '../images/Box-Model.PNG'
import '../../../sidebar/sidebar.css'
import '../../../tabs/tab.css'

const ModeloCaja = () => {
  useEffect(() => {
    document.title = 'Modelo De Caja en CSS'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Box Model (Modelo De Caja) CSS</h2>
        <p>Box Model</p>
        <p>En CSS el Modelo De Caja es una caja que se envuelve alrededor de un elemento HTML.</p>
        <ul className='lesson-ul'>
          <li>
            Todos los elementos HTML se pueden considerar en una caja
          </li>
          <li>
            Se consiste en el elemento HTML, padding, border, margin
          </li>
        </ul>
        <img src={modeloCaja} alt='Model De Caja' className='modelo-de-caja' />
        <br />
        <br />
        <p>Poninendo todo junto:</p>
        <ul className='lesson-ul'>
          <li>
            <strong>Content:</strong> El texto o las imágenes que utilices
          </li>
          <li>
            <strong>Padding:</strong>  El relleno alrededor del texto
          </li>
          <li>
            <strong>Border:</strong> La frontera que rodea el relleno y texto
          </li>
          <li>
            <strong>Margin:</strong> Despeja espacio afuera dela frontera
          </li>
        </ul>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default ModeloCaja
