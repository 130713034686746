import React, { useEffect } from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'
import '../../../tabs/tab.css'

const Relleno = () => {
  useEffect(() => {
    document.title = 'Padding (Relleno) en CSS'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Padding (Relleno) CSS</h2>
        <p>La propiedad <strong>Padding</strong></p>
        <p>En CSS se usa para generar espacio alrededor del contenido de un elemento</p>
        <div className='relleno-ejemplo'>
          Este elemento tiene un relleno de 48px
        </div>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssRelleno}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <h3>Padding tiene 4 lados</h3>
        <ul className='lesson-ul'>
          <li>
            padding-top (arriba)
          </li>
          <li>
            padding-right (derecha)
          </li>
          <li>
            padding-bottom (abajo)
          </li>
          <li>
            padding-left (izquierda)
          </li>
        </ul>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Relleno
