import React, { useEffect } from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'
import '../../../tabs/tab.css'

const Cssyhtml = () => {
  useEffect(() => {
    document.title = 'Cómo usar css con HTML'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Cómo usar CSS</h2>
        <p>Introducción al como usar CSS con HTML</p>
        <ul className='lesson-ul'>
          <li>
            CSS significa "Cascading Style Sheets
          </li>
          <li>
            CSS se utiliza para el estilo de elementos HTML
          </li>
        </ul>
        <p>Hay 3 formas de agregar css a tu proyecto html</p>
        <ul className='lesson-ul'>
          <li>
            En linea estilo
          </li>
          <li>
            Interno
          </li>
          <li>
            Externo
          </li>
        </ul>
        <hr className='style-line' />
        <h3>En linea CSS</h3>
        <p>
          En linea estilo - usando el attributo <code><span className='tag'>&lt;style&gt;</span></code> en tus elementos HTML, como lo hemos hecho en una <a href='attributes.html'><u>lección anterior</u></a>.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssEnlinea}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h2 style={{ backgroundColor: 'green', color: 'white', padding: '10px' }}>Hello World</h2>
              <p style={{ textAlign: 'center' }}>Aprende a programmar!</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>En linea CSS</h3>
        <p>
          Interno - se utiliza un CSS interno para definir un estilo para una sola página HTML.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssInterno}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h2 style={{ backgroundColor: 'green', color: 'white', padding: '10px' }}>Hello World</h2>
              <p style={{ textAlign: 'center' }}>Aprende a programmar!</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Externo CSS</h3>
        <ul className='lesson-ul'>
          <li>
            Los archivos CSS tienen la extensión <strong>.css</strong>
          </li>
          <li>
            Un archivo externo es útil cuando utiliza el mismo CSS en varias páginas
          </li>
          <li>
            Con una hoja de estilo externa, puede cambiar el aspecto de un sitio web completo, ¡cambiando un archivo!
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssExterno}
                </code>
              </pre>
            </div>
          </div>
          <div label='estilo.css'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssExternoStyle}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h2 style={{ backgroundColor: 'green', color: 'white', padding: '10px' }}>Hello World</h2>
              <p style={{ textAlign: 'center' }}>Aprende a programmar!</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Cssyhtml
