import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import '../../../sidebar/sidebar.css'

const Formatting = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Formateo HTML</h2>
        <p>Qué es un elemento HTML</p>
        <ul className='lesson-ul'>
          <li>
            Si usas un procesador de textos, debes estar familiarizado con la capacidad de poner el texto en negrita, en cursiva o subrayado
          </li>
          <li>
            Formateo de texto son etiquetas que escribimos en HTML rodeando la palabra o el texto que queremos transformar en el formato que nosotros queremos dar
          </li>
        </ul>
        <hr className='style-line' />

        <h3>Subrayado HTML</h3>
        <p>Texto dentro de la etiqueta <code><span className='tag'>&lt;u&gt;..&lt;/u&gt;</span></code> aparecerá subrayado.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmtFormateoSubrayado}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3>
                <u>texto subrayado</u>
              </h3>
            </div>
          </div>
        </Tabs>

        <hr className='style-line' />

        <h3>Bold/Oscuro HTML</h3>
        <p>
          Texto dentro de la etiqueta <code><span className='tag'>&lt;b&gt;..&lt;/b&gt;</span></code> o <code><span className='tag'>&lt;strong&gt;..&lt;/strong&gt;</span></code> mas oscuro.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmtFormateoSubrayado}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3>
                <b>texto mas oscur</b>
              </h3>
              <h3>texto regular</h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />

        <h3>Eliminado HTML</h3>
        <p>
          Texto dentro de la etiqueta <code><span className='tag'>&lt;del&gt;..&lt;/del&gt;</span></code> aparecerá eliminado.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmtFormateoEliminado}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3>
                <del>texto aparecerá eliminado</del>
              </h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />

        <h3>Citas HTML</h3>
        <p>
          Texto dentro de la etiqueta <code><span className='tag'>&lt;q&gt;..&lt;/q&gt;</span></code> aparecerá con comillas alrededor.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmtFormateoCitas}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3>
                El objectivo de Elon Musk es <q>ir a Mars</q>.
              </h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Formatting
