import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'
import '../../../tabs/tab.css'

const Fundamentos = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Fundamentos Básicos de Javascript</h2>
        <p>Programas De Javascript</p>
        <p>Un programa javascript es una lista de declaraciones (<strong>statements</strong>) que el navegador debe ejecutar.</p>
        <p>Cada declaración en javascript se ejecuta de arriba a abajo.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFundamentos}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <p>
                Agregar números con JavaScript
              </p>
              <p>11</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Código Limpio</h3>
        <p>Como en todos los idiomas hay reglas sobre cómo escribirlo.</p>
        <p>En javascript es importante agregar <strong>puntos y comas ( ; )</strong> al final de cada declaración, si no se agrega, no se ejecutará el codigo.</p>
        <p>Es importante escribir siempre un código que sea <strong>fácil de leer y mantener</strong>. Use comentarios si lo necesita y nunca olvide agregar un punto y coma después de haber terminado con una declaración.</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFundamentosLimpio}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>Espacios</h3>
        <p>Los espacios en javascript no afectarán tu código. El navegador los ignorará al ejecutar el código. Agregar espacios a tu código puede hacerlo más legible.</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFundamentosEspacios}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <p>Es una buena práctica agregar espacios alrededor de sus operadores (+ - * / =)</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFundamentosEspaciosOperadores}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>Longitud de línea y saltos de línea</h3>
        <p>Cuando una declaración no cabe en una sola línea, puede ser necesario romperla. Evite líneas de más de 80 palabras. Coloque el descanso después de un operador, idealmente después de una coma.</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFundamentosLongitud}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>Bloques de código</h3>
        <p>Los bloques de código son sentencias agrupadas entre paréntesis <strong>&#123;..&#125;</strong>,
          que se utilizan principalmente en las <strong>funciones</strong> cuando se desean ejecutar las declaraciones juntas.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFundamentosBloque}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Fundamentos
