import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const Comentarios = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Comentarios Javascript</h2>
        <p>Commentarios</p>
        <p>Los comentarios se utilizan para añadir información en el código. Hay 2 formas de comentarios <strong>Por línea</strong> y <strong>Por Bloque</strong>.</p>
        <p>Los comentarios son buenos para comentar el código que no desea que se ejecute, posiblemente porque no funciona.</p>
        <p>También pueden usar comentarios para agregar explicaciones a su código para que otros desarrolladores las lean.</p>
        <hr className='style-line' />
        <p><strong>Por línea</strong>: El código después de // barras no será ejecutado.</p>
        <p><strong>Ejemplo</strong> Nuestro getelementbyId no se ejecutará, ya que está siendo comentado por un solo comentario de línea.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsInlineComment}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <p><strong>Por bloque</strong>: Un slash seguido de un asterisco y termina con un asterisco seguido de un slash.</p>
        <p><strong>Ejemplo</strong>: esta función no se ejecutará porque estamos usando comentarios de varias líneas.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsBloqueComment}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Comentarios
