import React, { useEffect } from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'
import '../../../tabs/tab.css'

const Fondos = () => {
  useEffect(() => {
    document.title = 'Fondos en CSS'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Fondos CSS</h2>
        <p>CSS tiene 4 propiedades que están disponibles para usar cuando se modifica un fondo</p>
        <ul className='lesson-ul'>
          <li>
            background-color
          </li>
          <li>
            background-image
          </li>
          <li>
            background-repeat
          </li>
          <li>
            background-position
          </li>
        </ul>
        <hr className='style-line' />
        <h3>background-color</h3>
        <p><strong>background-color</strong> es la propiedad que especifica el color de fondo de un elemento.</p>
        <p>Se puede especificar el color de fondo usando: Nombre de colores, RGB, HEX.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssFondosBackground}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result-fondo-background'>
              <h3>el cuerpo de este ejemplo es de tipo de color naranja</h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>background-image</h3>
        <p><strong>background-image</strong> es la propiedad que se especifica para estableser una imagen en un elemento.</p>
        <p>Por defecto una imagen se repite Horizontal y verticalmente</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssFondosBackgroundImg}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result-fondo-background-img' />
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>background-repeat</h3>
        <p><strong>background-repeat</strong> es la propiedad que se especifica cuando una imagen se repite, Horizontal y verticalmente.</p>
        <p>"no-repeat" se usa cuando quieres que la imagen no se repite.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssFondosBackgroundImgRepeat}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result-fondo-background-img-noRepeat' />
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>background-position</h3>
        <p><strong>background-position</strong> es la propiedad que se especifica cuando queremeos cambiar la posición de la imagen.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssFondosBackgroundImgPosition}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result-fondo-background-img-Repeat' />
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Fondos
