import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import '../../../sidebar/sidebar.css'

const Comments = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Comentarios HTML</h2>
        <p>Cómo / por qué usar comentarios en HTML</p>
        <ul className='lesson-ul'>
          <li>
            Los comentarios en html se utilizan para escribir comentarios en su archivo html
          </li>
          <li>
            Los comentarios le permiten escribir una descripción para comprender por qué agregó / eliminó o necesita agregar algo a tu applicacion
          </li>
          <li>
            Comentarios ayudan a las personas con las que trabaja a entender su código
          </li>
          <li>
            Comentarios no se mostrarán en la página web solo en el archivo html
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Etiqueta de comentarios</h3>
        <p>Esta es la etiqueta para agregar comentarios a un HTML archivo</p>
        <div className='code-snippet-comments'>
          <span className='editor_comments'>&lt;!-- Escribe tu comentario aqui! --&gt;</span>
        </div>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlComentarios}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3>
                Mi comentario no se mostrará
              </h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        {/* Here just to show as a example */}
        {/* <iframe title='HTML code snippet' width='100%' height='300' src='//jsfiddle.net/hablacode/v7yj0ea8/11/embedded/html,result/dark/' allowfullscreen='allowfullscreen' allowpaymentrequest frameBorder='0' /> */}
      </div>
    </div>
  )
}

export default Comments
