const snippets_js = {

  javascript: `
<div id="demo"></div>
<button onclick="saludo()">click</button>

function saludo() {
  document.getElementById('demo').innerHTML = 'hello'
}
`.trim(),

  // I

  htmlJSExterno: `
<!DOCTYPE html>
<html>
<head>
  <title>JavaScript Intro</title>
</head>
<body>
  <h2>Usando HTML y JavaScript</h2>

  <script></script>
</body>
</html>
`.trim(),

  // II

  htmlJSExternoSrc: `
<!DOCTYPE html>
<html>
<head>
  <title>JavaScript Intro</title>
</head>
<body>
  <h2>Usando HTML y JavaScript</h2>
  
  <script src=""></script>
</body>
</html>
`.trim(),

  // III

  htmlJSExternoFile: `
<!DOCTYPE html>
<html>
<head>
  <title>JavaScript Intro</title>
</head>
<body>
  <h2>Usando HTML y JavaScript</h2>
  
  <script src='script.js'></script>
</body>
</html>
`.trim(),

  // Empty

  jsExternoScriptEmpty: `


`.trim(),

  // function

  jsExternoScriptFunc: `
function saludo() {
  return alert('¡Bienvenido a JavaScript!')
}
`.trim(),

  // use function

  htmlJSExternoFunc: `
<!DOCTYPE html>
<html>
<head>
  <title>JavaScript Intro</title>
</head>
<body>
  <h2>Usando HTML y JavaScript</h2>
  <button onclick='saludo()'>Intentalo</button>
  
  <script src='script.js'></script>
</body>
</html>
`.trim(),

  // Como Usar JavaScript Intro

  javascriptIntro: `
function saludo(nombre) {
  return 'Hola, ' + nombre
}

console.log(saludo('Carlos'));
`.trim(),

  // Como Usar JavaScript

  jsHead: `
<!DOCTYPE html>
<html>
<head>
<script>
 alert("Hola Javascript");
</script>
</head>

<body>
<p>Aparecerá una alerta al tratar este ejemplo.</p>
<p>La alerta sucederá porque estamos usando el método de alerta javascript.</p>
</body>
</html>
`.trim(),

  jsBody: `
<!DOCTYPE html>
<html>
<body>
  <p>Aparecerá una alerta al tratar este ejemplo.</p>
  <p>La alerta sucederá porque estamos usando el método de alerta javascript.</p>

  // Coloque siempre las etiqueta <script> al final para un mejor rendimiento
  <script>
   alert("Hola Javascript"); 
 </script>
</body>
</html>
`.trim(),

  jsExterno: `
<!DOCTYPE html>
<html>
<body>
  <p>Aparecerá una alerta al tratar este ejemplo.</p>
  <p>La alerta sucederá porque estamos usando el método de alerta javascript.</p>
  
  // La forma más limpia de agregar js a su página
  <script src="script.js"></script>
</body>
</html>
`.trim(),

  jsExternoScript: `
alert("Hola Javascript"); 
`.trim(),

  // Sintaxis

  jsVariablesSintaxis: `
let palabra = "Hola";
let saludo = "Hola Javascript";
`.trim(),

  jsLiteralsNum: `
<!DOCTYPE html>
<html>
<body>

<p>
 Ejemplo de cómo se pueden escribir los números en javascript.
</p>

<p id="demo"></p>
<p id="demo_2"></p>

<script>
document.getElementById("demo").innerHTML = 20.50;
document.getElementById("demo_2").innerHTML = 205;
</script>

<body>
<html>
`.trim(),

  jsLiteralsString: `
<!DOCTYPE html>
<html>
<body>

<p>Ejemplo de cómo se pueden escribir un string.</p>

<p id="ejemplo"></p>
<p id="ejemplo2"></p>

<script>
document.getElementById("ejemplo").innerHTML = "Carlos Del'";
document.getElementById("ejemplo2").innerHTML = 'Carlos Del';
</script>

<body>
<html>
`.trim(),

  jsLiteralsReserved: `
let palabra = "let"; // no hagas esto
let x = 6; // puedes asignar un número a un variable
`.trim(),

  jsLiteralsOperadores: `
let operaciónMatemática = (5 * 6) + 10 

console.log(operaciónMatemática)
`.trim(),

  jsLiteralsCase: `
let tiempo = 10;
let Tiempo = "diez";
`.trim(),

  // Fundamentos

  jsFundamentos: `
<!DOCTYPE html>
<html>
<body>

<p>Agregar números con JavaScript</p>
<p id="demo"></p>

<script>
let a = 1; ← declaración primera
let b = 10; ← declaración segunda
let total = a + b; ← declaración tercera
document.getElementById("demo").innerHTML = total; ← declaración final
</script>

<body>
<html>
`.trim(),

  jsFundamentosLimpio: `
<!DOCTYPE html>
<html>
<body>

<p>Agregar números con JavaScript</p>
<p id="demo"></p>

<script>
let a = 1;← (;) final declaración.
let b = 10;← (;) final declaración.
let total = a + b;← (;) final declaración.
document.getElementById("demo").innerHTML = total;← (;) final declaración.
</script>

<body>
<html>
`.trim(),

  jsFundamentosEspacios: `
<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let nombre = "John "; // espacio
let apellido = "Doe"; // espacio
let nombre_completo = nombre + apellido;
document.getElementById("demo").innerHTML = nombre_completo;
</script>

<body>
<html>
`.trim(),

  jsFundamentosEspaciosOperadores: `
let a = b + c; // Buena práctica
let a=b+c; // Mala práctica
`.trim(),

  jsFundamentosLongitud: `
<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let palabra = "javascript";
document.getElementById("test").innerHTML = "Bienvenido a "
+ palabra;
</script>

<body>
<html>
`.trim(),

  jsFundamentosBloque: `
function square(number) { ← función inico paréntesis
  return number * number; ← declaración uno
} ← función paréntesis final
`.trim(),

  jsWindow: `
<!DOCTYPE html>
<html>
<body>

<script>
 window.alert(mensaje);
</script>

<body>
<html>
`.trim(),

  jsConsole: `
<!DOCTYPE html>
<html>
<body>

<script>
  console.log(4*4)
</script>

<body>
<html>
`.trim(),

  jsInnerHtml: `
<!DOCTYPE html>
<html>
<body>

<p id="demo">Este texto cambiará</p>
<button onclick="cambiaTexto()">Click</button>

<script>
 function cambiaTexto() {
    document.getElementById("demo").innerHTML = "Nuevo texto";
 }
</script>

<body>
<html>
`.trim(),

  jsInlineComment: `
<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let one = 1; // esta variable almacena un número.
// document.getElementById("demo").innerHTML = one;
</script>

<body>
<html>
`.trim(),

  jsBloqueComment: `
<!DOCTYPE html>
<html>
<body>

<h4 id="demo" onclick="ejemplo()">Haz clic en mí para añadir nuevo texto.<h4>

<script>
/*
 function ejemplo() {
    document.getElementById("demo").innerHTML = "Nuevo texto";
*/
</script>

<body>
<html>
`.trim(),

  // Variables.js

  jsVariables: `
let a = 5;
let _ciudad = "los Angeles";
let numeroEjemplo = 23;
`.trim(),

  jsVariableNot: `
let 343 = "Hola"; // no puedes empezar con un número
let precio-camisa = 21; // guiones '-' no están permitidos en el nombre
`.trim(),

  jsVariableEjemplo: `
1.let palabra; // valor indefinido
2.let palabra = "Hola";
`.trim(),

  jsAritmeticas: `
<!DOCTYPE html>
<html>
<body>

<p id="demo"></p>

<script>
let precio = 20;
let impuestos = 5;
let total = precio + impuestos; // Nuestra variable total será asignada a sumar nuestros variables.
document.getElementById("demo").innerHTML = total; 
</script>

<body>
<html>
`.trim(),

  // Operadores.js

  jsOperadoresAritmeticos: `
let x = 10;
let y = 15;
let z = x + y;
console.log(z)
`.trim(),

  jsOperadoresAsignacion: `
let a = 5;
a *= 10;
console.log(a)
`.trim(),

  jsOperadoresCadena: `
let primerNombre = "Juan ";
let apellido = "Doe";
console.log(primerNombre + apellido)
`.trim(),

  jsOperadoresCadenaEjemplo2: `
let saludo = "Buenos días a ";
saludo += "todos";
console.log(saludo)
`.trim(),

  jsOperadoresCompar: `
let b = 10;
console.log(b < 9)
`.trim(),

  // Tipos De datos.js

  jsEjemploUno: `
let nombre = "Angelica";  // Cadena
let edad = 1996;  // Numero
let persona = { nombre: "Angelica, edad: 25 }; // Objeto
`.trim(),

  jsEjemploTwo: `
let a = 19 + "Chevy"; // number + cadena
`.trim(),

  jsEjemploThree: `
let a = "19" + "Chevy";  // cadena + cadena
`.trim(),

  jsEjemploFour: `
let b = "Hola" + 20  + 19;  
`.trim(),

  jsEjemploFive: `
let b = 20  + 19 + "Hola";
`.trim(),

  jsEjemploSix: `
let nombre = 'Juan Alberto'; // comillas simples
let nombre = "Juan Alberto"; //doble comillas
`.trim(),

  jsEjemploSeven: `
// comillas dobles dentro de comillas simples
let frase = 'Necesitarán comprar el libro “Psicología básica”';

// comillas simples dentro de comillas dobles
let frase2 = "Mi capítulo favorito del libro fue 'Formas de alcanzar tus objetivos'";

`,

  jsEjemploEight: `
let x = 23; // Escrito sin decimales
let z = 2.33; // Escrito con decimales
`.trim(),

  jsEjemploNine: `
let x = 10; 
console.log(x > 11);
console.log(x < 11);
`.trim(),

  jsEjemploArray: `
 // Array de cadenas
 let colores = ["Rojo", "Verde", "Azul"]; 
`.trim(),

  jsEjemploObjeto: `
let persona = { nombre: "John", apellido: "Doe" ,edad: 23 };
`.trim(),

  jsEjemploUndefined: `
let nombre; // valor es undefined, tipo es undefined
`.trim(),

  jsEjemploNull: `
let persona = { nombre: "John", apellido: "Doe" ,edad: 23 };
persona = null; // Ahora el valor es null, tipo dato es un objeto

`,

  jsEjemploNullTwo: `
let coche = { modelo: "Ford", color: "Rojo" , año: 2013 };
coche = undefined; // Ahora el valor es undefined, tipo dato es undefined

`,
  // Tipos De Funciones.js

  jsFuncionesNaming: `
function nombre_funcion()
`.trim(),

  jsFuncionesParentesis: `
function nombre_funcion(argumento_uno, argumentos_dos)
`.trim(),

  jsFunciones: `
function nombre_funcion(argumento_uno, argumentos_dos) {
  ... // código que se ejecutar
}
`.trim(),

  jsFuncionesExample: `
function saludo(nombre, apellido) {
  return nombre + ' ' + apellido
}
`.trim(),

  jsFuncionesCalling: `
saludo('Diego', 'Luna')
`.trim(),

  jsFuncionesConsole: `
console.log(saludo('Diego', 'Luna'))
`.trim(),

  // Objects.js

  jsObjects: `
let miMascota = new Object();
miMascota.nombre = 'Firulais';
miMascota.raza = 'Maltese'
miMascota.edad = 2019
`.trim(),

  jsObjectInit: `
let miMascota = {
  nombre: 'Firulais',
  raza: 'Maltese',
  edad: 2019
};
`.trim(),

  jsObjectUndefined: `
let miMascota = {
  nombre: 'Firulais',
  raza: 'Maltese',
  edad: 2019,
  color, // undefined
};
`.trim(),

  jsObjectAccesor: `
let miMascota = {
  nombre: 'Firulais',
  raza: 'Maltese',
  edad: 2019,
  color, // undefined
};

// Accesor de propiedad de punto
miMascota.nombre; // => 'Firulais'
`.trim(),

  jsObjectBracket: `
let miMascota = {
  nombre: 'Firulais',
  raza: 'Maltese',
  edad: 2019,
  color, // undefined
};

// Acceso a la propiedad entre corchetes: objeto ['propiedad']
miMascota['nombre'];   // => 'Firulais'
miMascota[raza]; // => 'Maltese'
`.trim(),

  jsObjectDestructuring: `
let miMascota = {
  nombre: 'Firulais',
  raza: 'Maltese',
  edad: 2019,
  color, // undefined
};

// Desestructuración de objetos
const { edad } = miMascota;
edad; // => 2019
`.trim()
}

export default snippets_js
