import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TitleSetter = ({ title, children }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location, title]);
  
  return children;
}

export default TitleSetter;