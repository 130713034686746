import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const Funciones = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Funciones Javascript</h2>
        <p>Funciones en Javascript:</p>
        <ul className='lesson-ul'>
          <li>Son un bloque de código que se realiza para una tarea específica</li>
          <li>No se ejecutan solas, se ejecutan cuando algo llama (invoca) una función</li>
        </ul>

        <hr className='style-line' />

        <h3>1. Declaraciones de funciones</h3>

        <p>En los pasos a continuación, le mostraremos cómo crear una función:</p>

        <p><strong>I.</strong> Una función en javascript está definida por la palabra clave "<strong>function</strong>", seguida de un <strong>nombre</strong>, seguido de paréntesis <strong>()</strong></p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFuncionesNaming}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <p>Un nombre de función válido comienza con una letra o un guión bajo, seguido por:</p>
        <ul className='lesson-ul'>
          <li>cualquier número</li>
          <li>letras</li>
        </ul>

        <br />

        <p><strong>II.</strong> Los <strong>argumentos</strong> se indican dentro de los paréntesis <strong>( )</strong> que van al lado del nombre de la función, argumentos son separados por comas</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFuncionesParentesis}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <p><strong>Argumentos</strong> son los <strong>valores</strong> que se le van a pasar ala función al invocar la función. Una función puede tener muchos argumentos.</p>
        <p>Los argumentos actúan como variables locales dentro de la función.</p>

        <br />

        <p><strong>III.</strong> El código que ejecutará la función estará dentro de <strong>&#123; &#125;</strong></p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFunciones}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <br />

        <p><strong>IV.</strong> Basándonos en lo que acabamos de aprender, ay que crear nuestra propia función que mostrará un nombre</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFuncionesExample}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />

        <h3>2. Llamando a una función</h3>

        <p>Llamando una función también se puede referir como 'invocando una función'.</p>
        <p>En el siguiente ejemplo, llamamos a nuestra función '<strong>saludo()</strong>' y pasamos nuestros dos argumentos de "nombre" y "apellido"</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFuncionesCalling}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <p>Si tuviéramos que registrar en la consola el resultado, recibiríamos el siguiente resultado:</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsFuncionesConsole}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>Diego Luna</p>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Funciones
