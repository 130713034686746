import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const Sintaxis = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Sintaxis Basicas de Javascript</h2>
        <p>La sintaxis de Javascript es un conjunto de reglas sobre cómo escribir el javascript adecuado.</p>
        <ul className='lesson-ul'>
          <li>
            Variables
          </li>
          <li>
            Reserved Keywords - Palabras clave reservadas
          </li>
          <li>
            Commentarios
          </li>
          <li>
            Operadores
          </li>
          <li>
            Case sensitive - distingue entre mayúsculas y minúsculas
          </li>
        </ul>
        <hr className='style-line' />
        <h3>Variables</h3>
        <p>En los lenguajes de programación, <b>variables</b> se utilizan para almacenar valores de datos.</p>
        <p>Para declarar un <strong>variable</strong> usas la palabra clave <strong>var</strong>.</p>
        <p>Para asignar un valor a un variable usas el <strong>signo igual</strong>.</p>
        <p>A continuación, declaramos un variable nombre <strong>palabra</strong> asignamos nuestra variable a hola.</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsVariablesSintaxis}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>Javascript literals</h3>
        <p>Hay dos tipos de valores, <strong>fixed values</strong> y <strong>variable values</strong>.</p>
        <p><strong>fixed values</strong>: Los valores fijos son números literales. Se puede escribir con o sin el punto decimal.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsLiteralsNum}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <p>Ejemplo de cómo se pueden escribir los números en javascript.</p>
              <p>20.50</p>
              <p>205</p>
            </div>
          </div>
        </Tabs>
        <p><strong>variable values</strong>: strings son texto, escritas entre comillas dobles o simples</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsLiteralsString}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <p>Ejemplo de cómo se pueden escribir un string.</p>
              <p>carlos Del</p>
              <p>Carlos Del</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Reserved Keywords</h3>
        <p><strong>Palabras clave reservadas</strong> son palabras clave utilizadas por javascript.</p>
        <p>Ejemplo: <strong>let</strong> es una palabra clave que es utilizada por javascript para declarar un variable. No se sugiere nombrar variables var, ni usarlas de ninguna otra manera.</p>
        <p>Aquí hay otras palabras clave utilizadas por javascript:</p>
        <ul className='lesson-ul'>
          <li>
            true, boolean, false
          </li>
          <li>
            byte, char, else, return
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsLiteralsReserved}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>Operadores</h3>
        <p>
          Javascript utiliza operaciones aritméticas estándar para calcular valores:
        </p>
        <ul className='lesson-ul'>
          <li>
            suma (+)
          </li>
          <li>
            resta (-)
          </li>
          <li>
            multiplicación (*)
          </li>
          <li>
            división (/)
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsLiteralsOperadores}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <p>40</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Case Sensitive</h3>
        <p>JavaScript es un lenguaje que <strong>distingue entre mayúsculas y minúsculas</strong>.</p>
        <p>
          Esto significa que las palabras clave del lenguaje, los variables, los nombres de las funciones y cualquier otro identificador deben escribirse siempre con mayúsculas y minúsculas.
        </p>
        <p>
          A continuación tenemos 2 variables, pero una es mayúscula y la otra en minúscula, y ambas significan 2 cosas diferentes.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsLiteralsCase}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Sintaxis
