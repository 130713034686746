import React from 'react'
import CssCodeSnippetComponent from '../../NewSnippets/CssCodeSnippetComponent'
import CssCodeSnippetComplex from '../../NewSnippets/CssCodeSnippetComplex'
import CssCodeSnippetComplexVersion from '../../NewSnippets/CssCodeSnippetComplexVersion'

const EstiloNuevo = () => {
  return (
    <div className="text-white min-h-screen overflow-hidden">
      <div className="md:ml-64 md:mr-40 mb-0 md:pt-8 lg:px-2 lg:text-lg text-base p-0">
        <h2 className="md:text-3xl text-2xl font-bold mb-4">Introduccion a CSS</h2>
        <CssCodeSnippetComponent />
        <div className="border-b border-solid border-transparent mb-4" />
        <CssCodeSnippetComplex />
        <div className="border-b border-solid border-transparent mb-4" />
        <CssCodeSnippetComplexVersion />
        <div className="mb-8"></div>
      </div>
    </div>
  )
}

export default EstiloNuevo
