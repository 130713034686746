import React, { useEffect } from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import selectorImg from '../images/selector.png'
import '../../../sidebar/sidebar.css'

const Selectores = () => {
  useEffect(() => {
    document.title = 'Selector básicos y Sintaxis CSS'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Selector básicos y Sintaxis CSS</h2>
        <p>CSS tiene su propia terminología para escribir el lenguaje.</p>
        <div className='img-container'>
          <img className='responsive-selector' alt='imagen de estilos predeterminados' src={selectorImg} />
        </div>
        <p><strong>Regla CSS:</strong></p>
        <ul className='lesson-ul'>
          <li>
            Cada regla está compuesta de una parte de "selectores", un símbolo de llave de apertura: "<strong>&#123;</strong>"
          </li>
          <li>
            otra parte denominada "declaración" que inclue la "propiedad" y el "valor" y por último
          </li>
          <li>
            un símbolo de llave de cierre: "<strong>&#125;</strong>"
          </li>
        </ul>
        <p><strong>Selector:</strong></p>
        <ul className='lesson-ul'>
          <li>
            Es la declaración del HTML elemento
          </li>
        </ul>
        <p><strong>Propiedad:</strong></p>
        <ul className='lesson-ul'>
          <li>
            La propiedad css que se está utilizando se ingresará en la sección de propiedades
          </li>
        </ul>
        <p><strong>Valor:</strong></p>
        <ul className='lesson-ul'>
          <li>
            El color verde y center es el valor, el valor será diferente dependiendo en la propiedad CSS que utilice
          </li>
        </ul>
        <p><strong>Brackets: &#123; &#125;</strong></p>
        <ul className='lesson-ul'>
          <li>
            Es muy <strong>importante</strong> ingresar siempre las brackets después de ingresar el elemento selector / nombre de id / nombre de clase. Sin las brackets el css no funcionará
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssSelectorIntro}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 style={{ color: 'green' }}>Ejemplo usando el selector css </h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>El clase selector</h3>
        <ul className='lesson-ul'>
          <li>
            El clase selector es para atribuir a todos los elementos de un HTML para luego aplicar estilos a los elementos marcados con esa clase usando hojas de estilo
          </li>
          <li>
            Tiene que tener un nombre, asegúrese de que el nombre sea único
          </li>
          <li>
            La forma de acceder a una clase en una hoja de estilo es escribiendo un punto (.) Y siguiéndola con el nombre de la clase
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssSelectorClase}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 style={{ color: 'purple', textAlign: 'center' }}>Ejemplo usando el clase selector </h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>El id selector</h3>
        <ul className='lesson-ul'>
          <li>
            El id selector especifica una identificación única para un elemento HTML (el valor debe ser único dentro el elemento HTML
          </li>
          <li>
            En CSS, para seleccionar un elemento con un ID específico, inserta un hash (#), seguido del ID del elemento
          </li>
          <li>
            El selector id también se puede usar en javascript, explicaremos en nuestra lección de javascript
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssSelectorId}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 style={{ color: 'red', textAlign: 'center' }}>Ejemplo usando el id selector</h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Selectores
