import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import '../../../sidebar/sidebar.css'

const Listas = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Listas HTML</h2>
        <hr className='style-line' />
        <h3>Lista en Orden</h3>
        <ul className='lesson-ul'>
          <li>
            La etiqueta para crear una lista ordenada se define usando la etiqueta <code><span className='tag'>&lt;ol&gt;&lt;/ol&gt;</span></code>
          </li>
          <li>
            <code><span className='tag'>&lt;li&gt;&lt;/li&gt;</span></code> etiqueta es como defines artículos en una lista
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlListasOrden}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <ol>
                <li>Artículo uno</li>
                <li>Artículo dos</li>
                <li>Artículo tres</li>
              </ol>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />

        <h3>Lista sin orden</h3>
        <ul className='lesson-ul'>
          <li>
            La etiqueta para crear una lista ordenada se define usando la etiqueta <code><span className='tag'>&lt;ul&gt;&lt;/ul&gt;</span></code>
          </li>
          <li>
            <code><span className='tag'>&lt;li&gt;&lt;/li&gt;</span></code> etiqueta es como defines artículos en una lista
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlListasSinOrden}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <ul>
                <li>Pastel</li>
                <li>Helado</li>
                <li>Dulces</li>
              </ul>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Listas
