import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HTMLTutorial from "./Components/technologies/html/HTML";
import CSSTutorial from "./Components/technologies/css/CSS";
import JavaScripTutorial from "./Components/technologies/javascript/JavaScript";
import Navbar from "./Components/navbar/Navbar";
import SideDrawer from "./Components/navbar/sidedrawer/SideDrawer";
import Backdrop from "./Components/navbar/backdrop/Backdrop";
import ScrollToTop from "./Components/hoc/ScrollToTop";
import Landing from "./Components/home/Landing";
import "react-lazy-load-image-component/src/effects/blur.css";

const App = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(true);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  return (
    <Router>
      <SideDrawer show={sideDrawerOpen} />
      {sideDrawerOpen && <Backdrop click={backdropClickHandler} />}

      <Navbar drawerClickHandler={drawerToggleClickHandler} />

      <ScrollToTop>
        <Route
          path="/"
          exact
          render={() => (
            <div>
              <Landing />
            </div>
          )}
        />

        <Route
          path="/html"
          render={() => (
            <div className="mt-14 md:mt-53px bg-stone-900">
              <HTMLTutorial />
            </div>
          )}
        />

        <Route
          path="/css"
          render={() => (
            <div className="mt-14 md:mt-53px bg-stone-900">
              <CSSTutorial />
            </div>
          )}
        />

        <Route
          path="/javascript"
          render={() => (
            <div className="mt-14 md:mt-53px bg-stone-900">
              <JavaScripTutorial />
            </div>
          )}
        />
      </ScrollToTop>
    </Router>
  );
};

export default App;
