import React, { useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Link,
  Redirect,
} from "react-router-dom";
import TitleSetter from "../../hoc/TitleSetter";

import { RiArrowDropUpFill } from "react-icons/ri";
import { FaCss3Alt } from "react-icons/fa";
import Cssyhtml from "./tutorials/Cssyhtml";
import Selectores from "./tutorials/Selectores";
import Comentarios from "./tutorials/Comentarios";
import Fondos from "./tutorials/Fondos";
import Relleno from "./tutorials/Relleno";
import Frontera from "./tutorials/Frontera";
import Margen from "./tutorials/Margen";
import ModeloCaja from "./tutorials/ModeloCaja";
import Texto from "./tutorials/Texto";
import Fuentes from "./tutorials/Fuentes";
import Mostrar from "./tutorials/Mostrar";
import AlturayAnchura from "./tutorials/AlturayAnchura";
import EstiloNuevo from "./tutorials/EstiloNuevo";
import PageNotFound from "../../../PageNotFound";

import "../../sidebar/sidebar.css";

const normalStyle = {
  display: "inline-block",
  transition: "1s, transform 1s",
};

const transformedStyle = {
  ...normalStyle,
  transform: "rotate(180deg)",
};

const CSS = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/css/");

  const [isActive, setActive] = useState("false");
  const [iconTransition, setIconTransition] = useState(false);

  const handleToggleAndClick = () => {
    setActive(!isActive);
    setIconTransition(!iconTransition);
  };

  const handleLinkClick = () => {
    setActive("false");
    setIconTransition(false);
  };

  return (
    <div className="side-nav-div text-neutral-200">
      <div className="toggle-button-lesson">
        <div className="toggle-con mb-4">
          <button className="lesson-button" onClick={handleToggleAndClick}>
            css / {splitLocation}
            <div style={iconTransition ? transformedStyle : normalStyle}>
              <RiArrowDropUpFill size={25} />
            </div>
          </button>
        </div>
      </div>
      <div className={`${isActive ? "sidenav" : "activeSideNav"}`}>
        <FaCss3Alt style={{ color: "#264de4" }} />
        <div className="list-wrapper">
          <div className="red-line" />

          <div className="list-item-wrapper">
            <Link to={`${path}/introducción-a-css`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "introducción-a-css"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "introducción-a-css"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Introducción a CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link
              to={`${path}/css-selectores-basicos-y-sintaxis`}
              onClick={handleLinkClick}
            >
              <div
                className={
                  splitLocation[1] === "css-selectores-basicos-y-sintaxis"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-selectores-basicos-y-sintaxis"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Selectores y Sintaxis
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-y-html`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "css-y-html"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-y-html"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Cómo usar CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-comentarios`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "css-comentarios"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-comentarios"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Comentarios CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-fondos`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "css-fondos"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-fondos"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Fondos CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-relleno`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "css-relleno"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-relleno"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Padding CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-frontera`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "css-frontera"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-frontera"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Border CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-margin`}>
              <div
                className={
                  splitLocation[1] === "css-margin"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-margin"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Margin CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/modelo-de-caja-css`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "modelo-de-caja-css"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "modelo-de-caja-css"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Modelo de Caja CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-texto`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "css-texto"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-texto"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Texto CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-fuentes`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "css-fuentes"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-fuentes"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Fuentes CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-mostrar`}>
              <div
                className={
                  splitLocation[1] === "css-mostrar"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-mostrar"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Display CSS
                </span>
                <div className="list-text" />
              </div>
            </Link>
          </div>

          <div className="list-item-wrapper">
            <Link to={`${path}/css-altura-y-anchura`} onClick={handleLinkClick}>
              <div
                className={
                  splitLocation[1] === "css-altura-y-anchura"
                    ? "list-bullet-active"
                    : "list-bullet"
                }
              />
              <div className="list-item-sidebar">
                <span
                  className={
                    splitLocation[1] === "css-altura-y-anchura"
                      ? "list-title-active"
                      : "list-title"
                  }
                >
                  Altura y Anchura CSS
                </span>
                <div className="list-text" />
              </div>
              <div className="white-line" />
            </Link>
          </div>
        </div>
      </div>
      <Switch>
        <Route
          exact
          path={path}
          render={() => <Redirect to={`${path}/introducción-a-css`} />}
        />
        <Route path={`${path}/introducción-a-css`}>
          <TitleSetter title="Introduccion a CSS">
            <EstiloNuevo />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-selectores-basicos-y-sintaxis`}>
          <TitleSetter title="Selector básicos y Sintaxis CSS">
            <Selectores />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-y-html`}>
          <TitleSetter title="Cómo usar css con HTML">
            <Cssyhtml />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-comentarios`}>
          <TitleSetter title="Comentarios en CSS">
            <Comentarios />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-fondos`}>
          <TitleSetter title="Fondos en CSSComentarios en CSS">
            <Fondos />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-relleno`}>
          <TitleSetter title="Padding (Relleno) en CSS">
            <Relleno />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-frontera`}>
          <TitleSetter title="Border en CSS">
            <Frontera />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-margin`}>
          <TitleSetter title="Margin en CSS">
            <Margen />
          </TitleSetter>
        </Route>
        <Route path={`${path}/modelo-de-caja-css`}>
          <TitleSetter title="Modelo De Caja en CSS">
            <ModeloCaja />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-texto`}>
          <TitleSetter title="Texto en CSS">
            <Texto />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-fuentes`}>
          <TitleSetter title="Fuentes en CSS">
            <Fuentes />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-mostrar`}>
          <TitleSetter title="Display en CSS">
            <Mostrar />
          </TitleSetter>
        </Route>
        <Route path={`${path}/css-altura-y-anchura`}>
          <TitleSetter title="Altura y anchura en CSS">
            <AlturayAnchura />
          </TitleSetter>
        </Route>
        <Route path={`${path}/*`}>
          <TitleSetter title="Page Not Found">
            <PageNotFound />
          </TitleSetter>
        </Route>
      </Switch>
    </div>
  );
};

export default CSS;