import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const Variables = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Variables Javascript</h2>
        <p>Se utilizan para almacenar valores de <strong>data</strong>(datos).</p>
        <p>Crear una variable en javascript se llama declarar una variable.</p>
        <p>Existen algunas reglas al declarar una variable de JavaScript tambien conosido come un <strong>identificador</strong>.</p>
        <ul className='lesson-ul'>
          <li>El nombre debe de empesar con una letra.</li>
          <li>El nombre debe contener solo letras (a to z o A to Z), dígitos o los símbolos $ y _.</li>
          <li>Después de la primera letra podemos usar dígitos (0 a 9), por ejemplo precio1.</li>
          <li>Los nombres distinguen entre mayúsculas y minúsculas, example: y e Y son cosas diferentes.</li>
          <li>No puedes usar una palabra reservada como variable.</li>
        </ul>
        <p><strong>Forma correcta de escribir una variable</strong></p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsVariables}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <p><strong>Forma incorrecta de escribir una variable</strong></p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsVariableNot}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>Ejemplo de un variable javascript</h3>
        <p>1. Declara un variable usando la palabra clave let después de declararla. Si no le asigna un valor, no estará definido(<strong>undefined</strong>) hasta que lo haga.</p>
        <p>2. Asignaré su valor utilizando el signo igual (=).</p>
        <p>El variable <strong>let</strong> almacena el valor "Hola".</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsVariableEjemplo}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>
        <hr className='style-line' />
        <h3>Aritméticas Javascript</h3>
        <p>Como con el álgebra también puedes hacer la aritmética con javascript.</p>
        <p>Utilizando el signo igual =, y su elección de + - * /</p>
        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsAritmeticas}
                </code>
              </pre>
            </div>
          </div>
          <div label='Console'>
            <div className='code-snippet-command-result'>
              <p>25</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Variables
