import React, { useEffect } from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const Fuentes = () => {
  useEffect(() => {
    document.title = 'Fuentes en CSS'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Fonts (fuentes) CSS</h2>
        <p>La propiedad <strong>font</strong> se utiliza para definir el tipo de fuente, negrita, tamaño, y estilo de texto.</p>
        <p>La elección de una tipografía adecuada es muy importante para diseñar una pagina del web.</p>
        <hr className='style-line' />
        <h3>font-family</h3>
        <p>La propiedad <strong>font-family</strong> se utiliza para establecer el tipo de fuente.</p>
        <p>La propiedad <strong>font-family</strong> puede contener varioes nombres de fuentes como un sistema alternativo en caso que el web no admite la primera fuente, la segunda fuente es la fuente siguiente.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssTextfont}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 className='text-color-font-h3'>Un párrafo usando Arial </h3>
              <h4 className='text-color-font-h4'>Un párrafo usando Times New Roman </h4>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>font-style</h3>
        <p>La propiedad <strong>font-style</strong> se usa para definir 3 estilos de fuente:</p>
        <ul className='lesson-ul'>
          <li>
            normal
          </li>
          <li>
            italico
          </li>
          <li>
            oblique
          </li>
        </ul>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssTextfontStyle}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <p className='text-color-font-style-p-1'>Un párrafo usando texto normal</p>
              <p className='text-color-font-style-p-2'>Un párrafo usando texto italico</p>
              <p className='text-color-font-style-p-3'>Un párrafo usando texto oblique</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>font-size</h3>
        <p>La propiedad <strong>font-size</strong> se utiliza para establecer el tamaño de texto.</p>
        <p>Es importante controlar el tamaño de un texto, pero no es necesario hacer un <span className='tag'>&lt;p&gt;</span> al tamaño que un <span className='tag'>&lt;h1&gt;</span>.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssTextfontSize}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3 className='text-color-font-size-h3'>Titulo h3 con tamaño 48px </h3>
              <p className='text-color-font-size-p'>Titulo parrafo con tamaño 2rem </p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>font-weight</h3>
        <p>La propiedad <strong>font-weight</strong> se utiliza para establecer el peso de fuente.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssTextfontWeight}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <p className='text-color-font-weight-n'>Un párrafo normal</p>
              <p className='text-color-font-weight-b'>Un párrafo oscuro</p>
              <p className='text-color-font-weight-l'>Un párrafo con peso 300 (claro)</p>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Fuentes
