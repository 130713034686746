import React from 'react'
import JavaScriptCodeSnippetComponent from '../../NewSnippets/JavaScriptCodeSnippetComponent'
import JavaScriptDataTypesCodeSnippetComponent from '../../NewSnippets/JavaScriptDataTypesCodeSnippetComponent'
import JavaScriptSyntaxCodeSnippetComponent from '../../NewSnippets/JavaScriptSyntaxCodeSnippetComponent'

const Introduccion = () => {
  return (
    <div className="text-white min-h-screen overflow-hidden">
      <div className="md:ml-64 md:mr-40 mb-0 md:pt-8 lg:px-2 lg:text-lg text-base p-0">
        <h2 className="md:text-3xl text-2xl font-bold mb-4">Introducción a JavaScript</h2>
        <JavaScriptCodeSnippetComponent />
        <div className="border-b border-solid border-transparent mb-4"/>
        <JavaScriptDataTypesCodeSnippetComponent />
        <div className="border-b border-solid border-transparent mb-4"/>
        <JavaScriptSyntaxCodeSnippetComponent />
      </div>
    </div>
  )
}

export default Introduccion
