import React from "react";
import HtmlCodeSnippetComponent from "../../NewSnippets/HtmlCodeSnippetComponent";
import HtmlCssCodeSnippetComponent from "../../NewSnippets/HtmlCssCodeSnippetComponent";
import HtmlCssExternalCodeSnippetComponent from "../../NewSnippets/HtmlCssExternalCodeSnippetComponent";
import HtmlCssJsCodeSnippetComponent from "../../NewSnippets/HtmlCssJsCodeSnippetComponent";

const Elements = () => {
  return (
    <div className="text-white min-h-screen overflow-hidden">
      <div className="md:ml-64 md:mr-40 mb-0 md:pt-8 lg:px-2 lg:text-lg text-base p-0">
        <h2 className="md:text-3xl text-2xl font-bold mb-4">Elementos HTML</h2>
        <HtmlCodeSnippetComponent />
        <div className="border-b border-solid border-transparent mb-4" />
        <HtmlCssCodeSnippetComponent />
        <div className="border-b border-solid border-transparent mb-4" />
        <HtmlCssExternalCodeSnippetComponent />
        <div className="border-b border-solid border-transparent mb-4" />
        <HtmlCssJsCodeSnippetComponent />
        {/* <div className="relative pt-[56.25%]">
          <div className="absolute top-1/2 left-1/2 z-10 bg-hablacode-gradient text-black px-4 md:px-46 py-2 md:py-3 rounded-full text-xs md:text-sm flex items-center justify-center transform -translate-x-1/2 -translate-y-1/2 whitespace-nowrap min-w-max">
            hablacode +
          </div>

          16:9 Aspect Ratio
          <ReactPlayer
            className="absolute top-0 left-0 filter blur-sm"
            url="https://youtu.be/q1Y5ipF85tA?si=UHyAUVX1TZSdRITt"
            width="100%"
            height="100%"
          />
        </div> */}
        <div className="mb-8"></div>
      </div>
    </div>
  );
};

export default Elements;
