import React from 'react';

// Define the main colors used in VS Code's theme for HTML and CSS
const vscodeTheme = {
    background: '#272727',
    text: '#D4D4D4',
    tag: '#569CD6',
    attributeName: '#9CDCFE',
    attributeValue: '#CE9178',
    cssSelector: '#D4D4D4',
    cssProperty: '#9CDCFE',
    cssValue: '#CE9178',
    comment: '#6A9955',
};

// A component that displays HTML and external CSS code snippets with syntax highlighting
const HtmlCssExternalCodeSnippetComponent = () => {
    const explanationStyle = {
        backgroundColor: vscodeTheme.background,
        color: vscodeTheme.text,
        padding: '20px',
        fontFamily: 'Consolas, "Courier New", monospace',
        borderRadius: '5px',
        marginBottom: '1.5rem',
        borderLeft: '4px solid'
    };

    const codeStyle = {
        ...explanationStyle,
        border: '1px solid #fff',
        marginTop: '10px',
        whiteSpace: 'pre',
        overflowX: 'auto',
        borderLeft: '1px solid #fff'
    };

    const explanation = 'Este ejemplo muestra un fragmento de HTML vinculado a un archivo CSS externo.';

    return (
        <div className='mb-8'>
            <div style={explanationStyle}>{explanation}</div>
            <pre style={codeStyle}>
                <span style={{ color: vscodeTheme.comment }}>&lt;!-- HTML file (index.html) --&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;!DOCTYPE html&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;html&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;head&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;link</span> <span style={{ color: vscodeTheme.attributeName }}>rel</span>=<span style={{ color: vscodeTheme.attributeValue }}>"stylesheet"</span> <span style={{ color: vscodeTheme.attributeName }}>href</span>=<span style={{ color: vscodeTheme.attributeValue }}>"styles.css"</span><span style={{ color: vscodeTheme.tag }}>&gt;&lt;/link&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;/head&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;body&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;div</span> <span style={{ color: vscodeTheme.attributeName }}>class</span>=<span style={{ color: vscodeTheme.attributeValue }}>"container"</span><span style={{ color: vscodeTheme.tag }}>&gt;</span><br />
                &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;h1&gt;</span>Hello World<span style={{ color: vscodeTheme.tag }}>&lt;/h1&gt;</span><br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.tag }}>&lt;/div&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;/body&gt;</span><br />
                <span style={{ color: vscodeTheme.tag }}>&lt;/html&gt;</span><br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>&lt;!-- CSS file (styles.css) --&gt;</span><br />
                <span style={{ color: vscodeTheme.cssSelector }}>.container </span>{'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>background-color</span>: <span style={{ color: vscodeTheme.cssValue }}>#1E1E1E</span>;<br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.cssProperty }}>color</span>: <span style={{ color: vscodeTheme.cssValue }}>#D4D4D4</span>;<br />
                {'}'}
            </pre>
        </div>
    );
};

export default HtmlCssExternalCodeSnippetComponent;
