import React from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codeSamples'
import redCube from '../images/cubo_rojo.jpg'
import '../../../sidebar/sidebar.css'

const Imagenes = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Imágenes en HTML</h2>
        <p>
          Como se indica en nuestra lección de enlaces. Esta es la forma estándar de agregar una imagen a su proyecto.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlImgSyntax}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <img src={redCube} height='300' width='250' alt='Un cubo rojo' />
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Imagenes html - atl</h3>
        <p>
          El atributo <span className='text_editor_attribute'>alt</span> proporciona un texto alternativo para una imagen, si el usuario por alguna razón no puede verlo (debido a una conexión lenta, un error en el atributo src, o si el usuario usa un lector de pantalla).
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlImgAlt}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <h3>Un cubo rojo</h3>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Tamaño de imagen - Ancho y Altura</h3>
        <p>
          Utilizando <b>Width</b> y <b>Height</b> te permite personalizar el tamaño de la imagen
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlImgAnchoYAltura}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <p>Tamaño personalizar</p>
              <img src={redCube} height='150' width='150' alt='Un cubo rojo' />
              <br />
              <br />
              <p>Tamaño regular</p>
              <img src={redCube} alt='Un cubo rojo' />
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Imagen como enlace</h3>
        <p>
          Para usar una imagen como enlacio envolve la etiqueta <span className='tag'>&lt;a&gt;&lt;/a&gt;</span> alrededor de la etiqueta <span className='tag'>&lt;img&gt;</span>.
        </p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.htmlImgEnlace}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <img src={redCube} height='300' width='250' alt='Un cubo rojo' />
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Imagenes
