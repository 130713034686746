import React, { useEffect } from 'react'
import Tabs from '../../../tabs/Tabs'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const AlturayAnchura = () => {
  useEffect(() => {
    document.title = 'Altura y anchura en CSS'
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Altura y Anchura CSS</h2>
        <p><strong>height</strong> and <strong>width</strong> son las propiedades para modificar la altura y anchura de un elemento HTML.</p>
        <p>El navegador utiliza el valor <strong>auto</strong> para calcular la altura y el ancho de un elemento. también puede especificar valores de longitud utilizando <strong>px</strong>, <strong>rem</strong> y el valor porcentual <strong>(%)</strong> del bloque contenedor.</p>
        <hr className='style-line' />
        <h3>Alruta (height)</h3>
        <p>La propiedad en CSS que controla la altura de un elemento HTML es la prodiedad <strong>height</strong>.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssHeight}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <div className='ejemplo-altura'>Este elemento tiene una Altura de 200px y una Anchura de 25%</div>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Anchura (width)</h3>
        <p>La propiedad CSS que controla la anchura de un elemento HTML es la prodiedad <strong>width</strong>.</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssWidth}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <div className='ejemplo-achura'>Este elemento tiene una Anchura de 50%</div>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
        <h3>Usando Altura y Anchura</h3>
        <p>También puedes usar ambas propiedades al mismo tiempo</p>
        <Tabs className='text-editor-tab'>
          <div label='index.html'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.cssWidthHeight}
                </code>
              </pre>
            </div>
          </div>
          <div label='Resultado'>
            <div className='code-snippet-result'>
              <div className='ejemplo-achura-altura'>altura: 5rem y ancho: 75%</div>
            </div>
          </div>
        </Tabs>
        <hr className='style-line' />
      </div>
    </div>
  )
}

export default AlturayAnchura
