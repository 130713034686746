import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { IoLogoHtml5 } from "react-icons/io";
import {
  FaCss3Alt,
  FaFacebook,
  FaSpotify,
  FaYoutube,
} from "react-icons/fa";
import { DiJavascript } from "react-icons/di";
import "./SideDrawer.css";

const SideDrawer = ({ show }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  const links = [
    { path: "/", text: "Inicio", exact: true }, // Specify 'end' here
  ];

  return (
    <nav className={show ? "side-drawer open" : "side-drawer"}>
      <ul className="list-none mt-4">
        {links.map(({ path, text }) => (
          <li key={path} className="py-2">
            <NavLink
              to={path}
              className="text-lg text-white px-4 py-2 rounded-md transition-colors duration-300 flex items-center justify-between w-full"
            >
              {text}
            </NavLink>
          </li>
        ))}
        <li className="py-2" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="text-lg text-white px-4 py-2 rounded-md transition-colors duration-300 flex items-center justify-between w-full"
          >
            Desarollo Web
            <svg
              className={`ml-2 w-4 h-4 transform transition-transform duration-200 ${
                dropdownOpen ? "rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {dropdownOpen && (
            <ul className="absolute left-0 mt-2 w-full bg-[#161515] shadow-lg rounded-md py-1 z-50">
              <li>
                <NavLink
                  to="/html"
                  className="flex items-center space-x-2 px-4 py-2 text-sm text-white hover:bg-gray-700"
                >
                  <IoLogoHtml5 style={{ color: "#e34c26" }} />
                  <span>HTML</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/css"
                  className="flex items-center space-x-2 px-4 py-2 text-sm text-white hover:bg-gray-700"
                >
                  <FaCss3Alt style={{ color: "#264de4" }} />
                  <span>CSS</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/javascript"
                  className="flex items-center space-x-2 px-4 py-2 text-sm text-white hover:bg-gray-700"
                >
                  <DiJavascript style={{ color: "#F0DB4F" }} />
                  <span>JavaScript</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/"
                  className="flex items-center space-x-2 px-4 py-2 text-sm text-white hover:bg-gray-700"
                >
                  <FaReact style={{ color: "#242443" }} />
                  <span>React</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/"
                  className="flex items-center space-x-2 px-4 py-2 text-sm text-white hover:bg-gray-700"
                >
                  <FaWix />
                  <span>Wix</span>
                </NavLink>
              </li> */}
            </ul>
          )}
        </li>
      </ul>
      <div className="flex justify-around items-center w-full p-4 absolute bottom-0">
        <a
          href="https://www.facebook.com/hablacode"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook className="text-white text-1xl hover:text-blue-600" />
        </a>
        <a
          href="https://www.spotify.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaSpotify className="text-white text-1xl hover:text-green-600" />
        </a>
        <a
          href="https://www.youtube.com/@HablaCodeorg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube className="text-white text-1xl hover:text-red-600" />
        </a>
      </div>
    </nav>
  );
};

export default SideDrawer;
