import React from 'react'
import Tabs from '../../../tabs/Tabs'
import jsDog from '../images/dog.png'
import snippet from '../codesnippets'
import '../../../sidebar/sidebar.css'

const Objetos = () => {
  return (
    <div className='lesson-main-div'>
      <div className='main'>
        <h2>Objetos Javascript</h2>
        <p>Objetos en Javascript:</p>
        <ul className='lesson-ul'>
          <li>Representa uno de los tipos de datos de JavaScript. Un objeto es un tipo de datos estructurado no primitivo en JavaScript</li>
          <li>Es una colección desordenada de pares clave-valor, <strong>&#123; clave: valor &#125;</strong></li>
          <li>La propiedad <strong>clave</strong> puede ser una cadena/string</li>
          <li>El <strong>valor</strong> de una propiedad puede ser cualquier valor válido de JavaScript, por ejemplo, una cadena, un número, un array[] e tambien una función</li>
          <li>Asignar una función como valor de propiedad también se puede llamar método</li>
        </ul>

        <hr className='style-line' />
        <h3>Objeto en la vida real</h3>
        <p>La mejor manera de explicar qué es un objeto en JavaScript es con un ejemplo de la vida real. En la vida real, una mascota es un objeto:</p>
        {/* 200 x 200 */}
        <div className='flex-container'>
          <img className='responsive-object-img' alt='perro ejemplo' src={jsDog} />
        </div>

        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope='col'>Propiedades</th>
              <th scope='col'>Métodos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>mascota.nombre = firulais</td>
              <td>Amascota.come()</td>
            </tr>
            <tr>
              <td>mascota.color = cafe</td>
              <td>mascota.ladrar()</td>
            </tr>
            <tr>
              <td>mascota.raza = maltese</td>
              <td>mascota.corre()</td>
            </tr>
          </tbody>
        </table>

        <p>En el ejemplo de arriba, estamos usando una mascota como nuestro ejemplo de objeto. El nombre de nuestra mascota es "Firulais", y nuestra mascota tiene propiedades que definen sus características.</p>

        <hr className='style-line' />

        <h3>1. Cómo crear un objeto en JS</h3>

        <p>Vamos a crear un objeto y llamarla <strong>miMascota</strong> y darle propiedades llamadas raza, color, edad</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsObjects}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <br />

        <p>También podemos crear el mismo objeto de arriba usando el <strong>inicializador de objetos</strong></p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsObjectInit}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <br />

        <p>Las propiedades que no tengan un valor asignado, serán indefinidas/undefined</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsObjectUndefined}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />

        <h3>2. (3) formas de acceder a las propiedades de un objeto en JS</h3>

        <br />

        <p><strong>I.</strong> Accesor de propiedad de punto: objeto.propiedad</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsObjectAccesor}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <br />

        <p><strong>II.</strong> Acceso a la propiedad entre corchetes: objeto ['propiedad']</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsObjectBracket}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <br />

        <p><strong>III.</strong> Desestructuración de objetos: const  &#123; propiedad &#125; = objeto</p>

        <Tabs className='text-editor-tab'>
          <div label='script.js'>
            <div className='code-snippet-json'>
              <pre>
                <code>
                  <span className='code-block' />
                  {snippet.jsObjectDestructuring}
                </code>
              </pre>
            </div>
          </div>
          <div />
        </Tabs>

        <hr className='style-line' />
      </div>
    </div>
  )
}

export default Objetos
