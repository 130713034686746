import React from 'react';
import './PageNotFound.css'; 

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      Page Not Found :/ 
    </div>
  );
}

export default PageNotFound;