import React from 'react';

// Define the main colors used in VS Code's theme for JavaScript
const vscodeTheme = {
    background: '#272727',
    text: '#D4D4D4',
    jsKeyword: '#C586C0',
    jsFunction: '#DCDCAA',
    jsString: '#CE9178',
    jsNumber: '#B5CEA8',
    jsBoolean: '#569CD6',
    comment: '#6A9955',
};

// A component that displays a JavaScript code snippet focusing on syntax rules
const JavaScriptSyntaxCodeSnippetComponent = () => {
    const explanationStyle = {
        backgroundColor: vscodeTheme.background,
        color: vscodeTheme.text,
        padding: '20px',
        fontFamily: 'Consolas, "Courier New", monospace',
        borderRadius: '5px',
        marginBottom: '1.5rem',
        borderLeft: '4px solid'
    };

    const codeStyle = {
        ...explanationStyle,
        border: '1px solid #fff',
        marginTop: '10px',
        whiteSpace: 'pre',
        overflowX: 'auto',
        borderLeft: '1px solid #fff'
    };

    const explanation = 'Este ejemplo demuestra la sintaxis básica de JavaScript, incluidas variables, funciones, bucles y estructuras de control.';

    return (
        <div className='mb-8'>
            <div style={explanationStyle}>{explanation}</div>
            <pre style={codeStyle}>
                <span style={{ color: vscodeTheme.comment }}>{"//"} Function definition</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>function</span> <span style={{ color: vscodeTheme.jsFunction }}>greet</span>(<span style={{ color: vscodeTheme.jsString }}>name</span>) {'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.jsKeyword }}>return</span> <span style={{ color: vscodeTheme.jsString }}>'Hello, '</span> + <span style={{ color: vscodeTheme.jsString }}>name</span>;<br />
                {'}'}<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"//"} Variable declaration</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>const</span> message <span style={{ color: vscodeTheme.jsKeyword }}>=</span> <span style={{ color: vscodeTheme.jsFunction }}>greet</span>(<span style={{ color: vscodeTheme.jsString }}>'Alice'</span>);<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"//"} If-else statement</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>if</span> (message <span style={{ color: vscodeTheme.jsKeyword }}>===</span> <span style={{ color: vscodeTheme.jsString }}>'Hello, Alice'</span>) {'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.jsFunction }}>console</span>.<span style={{ color: vscodeTheme.jsFunction }}>log</span>(<span style={{ color: vscodeTheme.jsString }}>'Greeting confirmed.'</span>);<br />
                {'}'} <span style={{ color: vscodeTheme.jsKeyword }}>else</span> {'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.jsFunction }}>console</span>.<span style={{ color: vscodeTheme.jsFunction }}>log</span>(<span style={{ color: vscodeTheme.jsString }}>'Incorrect greeting.'</span>);<br />
                {'}'}<br />
                <br />
                <span style={{ color: vscodeTheme.comment }}>{"//"} For loop</span><br />
                <span style={{ color: vscodeTheme.jsKeyword }}>for</span> (<span style={{ color: vscodeTheme.jsKeyword }}>let</span> i <span style={{ color: vscodeTheme.jsKeyword }}>=</span> <span style={{ color: vscodeTheme.jsNumber }}>0</span>; i <span style={{ color: vscodeTheme.jsKeyword }}>&lt;</span> <span style={{ color: vscodeTheme.jsNumber }}>5</span>; i<span style={{ color: vscodeTheme.jsKeyword }}>++</span>) {'{'}
                <br />
                &nbsp;&nbsp;<span style={{ color: vscodeTheme.jsFunction }}>console</span>.<span style={{ color: vscodeTheme.jsFunction }}>log</span>(i);<br />
                {'}'}<br />
            </pre>
        </div>
    );
};

export default JavaScriptSyntaxCodeSnippetComponent;
