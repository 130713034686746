const snippets = {

  // Introduction

  cssIntro: `
<!DOCTYPE html>
<html>
<head>
  <link rel="stylesheet" href="estilo.css">
</head>
<body>
  <h3>Este encabezado está diseñado por CSS</h3>
</body>
</html>
`.trim(),

  cssIntroExternalCSS: `
h3 {
  color: green;
  font-size: 2rem;
  text-decoration: underline;
}
`.trim(),

  // adding more css to our above example

  cssIntroPartTwo: `
<!DOCTYPE html>
<html>
<head>
  <link rel="stylesheet" href="estilo.css">
</head>
<body>
  <div class="titulo-con">
    <h1 id="titulo">Hola Mundo</h1>
  </div>
  <h3>Este encabezado está diseñado por CSS</h3>
</body>
</html>
`.trim(),

  cssIntroExternalCSSPartTwo: `
#titulo {
  color: rgb(224, 223, 223);
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}

.titulo-con {
  padding: 1rem;
  background-color: rgb(93, 78, 182);
}

h3 {
  color: green;
  font-size: 2rem;
  text-decoration: underline;
}
`.trim(),

  cssLink: `
<link rel="stylesheet" href="estilo.css">
`.trim(),

  // ---
  cssSelectorIntro: `
<!DOCTYPE html>
<html>
<head>
<style>
h3 { color: green; }
</style>
</head>

<body>
  <h3>Ejemplo usando el selector css </h3>
</body>
</html>
`.trim(),

  cssSelectorClase: `
<!DOCTYPE html>
<html>
<head>
<style>
.ejemplo {
  color: green;
 text-align: center;
}
</style>
</head>

<body>
 <h3 class="ejemplo">Ejemplo usando el clase selector</h3>
</body>
</html>
`.trim(),

  cssSelectorId: `
<!DOCTYPE html>
<html>
<head>
<style>
#titulo {
 color: red;
 text-align: center;
}
</style>
</head>

<body>
 <h3 id="titulo">Ejemplo usando el id selector</h3>
</body>
</html>
`.trim(),

  // Como Usar CSS
  cssEnlinea: `
<h2 style="background-color: verde;color:blanco;padding:10px;">Hello World</h2>
<p style="text-align: centrar;">Aprende a programmar!</p>
`.trim(),

  cssInterno: `
<!DOCTYPE html>
<html>
<head>
<style>
h3 {
 background-color: green;
 color: white;
 padding: 10px;
}
p {  
 text-align: center;
}
</style>
</head>

<body>
 <h3>Hola Mundo</h3>
 <p>Aprende a programmar!</p>
</body>
</html>
`.trim(),

  cssExterno: `
<!DOCTYPE html>
<html>
<head>
<link rel="stylesheet" href="estilo.css">
</head>

<body>
 <h2>Hola Mundo</h2>
 <p>Aprende a programmar!</p>
</body>
</html>
`.trim(),

  cssExternoStyle: `
h2 {
  background-color: green;
  color: white;
  padding: 10px;
}
p {
  text-align: center;
}
`.trim(),

  cssComentarios: `
body {  
 background-color: #f4a142;   
 font-size: 2rem; /* comentario de una línea */ 

/* El comentario a continuación se usa para deshabilitar estilos específicos */

/* 
  font-family: sans-serif; Comentario de multilínea
*/
} 
`.trim(),

  // Comments CSS

  cssComments: `
/* Escribe tu comentario asi! */
`.trim(),

  cssCommentsHtml: `
<!DOCTYPE html>
<html>
<head>
  <link rel="stylesheet" href="estilo.css">
</head>
<body>
  <h3>Este encabezado está diseñado por CSS</h3>
</body>
</html>
`.trim(),

  // Fondos CSS

  cssFondosBackground: `
<style>
body {
 background-color: #f4a142;
}
</style>

<body>
  <h3>el cuerpo de este ejemplo es de tipo de color naranja</h3>
</body>
`.trim(),

  cssFondosBackgroundImg: `
<style>
body {
  background-image:url(montaña.jpg);
}
</style>

<body>
</body>
`.trim(),

  cssFondosBackgroundImgRepeat: `
<style>
body {
 background-image:url(montaña.jpg);
 background-repeat: no-repeat;
} 
</style> 

<body>
</body>
`.trim(),

  cssFondosBackgroundImgPosition: `
<style>
body {
 background-image:url(montaña.jpg);
 background-repeat: no-repeat;
 background-position: right;  
}
</style>

<body>
</body>
`.trim(),

  // Relleno CSS

  cssRelleno: `
<style>
.texto {
  padding: 48px;
  border-style: solid;
  border-color: red;
}
</style>

<body>
 <div class="texto">Este elemento tiene un relleno de 48px</div>
</body>
`.trim(),

  // Margen CSS

  cssMargen: `
<style>
.texto {
 border: 1px solid black;
 background-color: rgb(165, 248, 149);
 margin-top: 50px;
 margin-bottom: 50px;
 margin-right: 150px;
 margin-left: 80px;
}
</style>

<body>
 <div id="texto">Este elemento esta usando la propiedad margin</div>
</body>
`.trim(),

  // Texto CSS

  cssTextColor: `
<!DOCTYPE html>
<html>
<head>
<style>
h3 {
 color: rgb(53, 145, 53);
}
p {
 color: blue;
}
</style>
</head>

<body>
 <h3>Hola Mundo</h3>
 <p>Este texto tiene color azul </p>
</body>
</html>
`.trim(),

  cssTextAlign: `
<!DOCTYPE html>
<html>
<head>
<style>
h3 {  
 color: rgb(53, 145, 53);
 text-align: center;
    
} 
</style> 
</head>
        
<body>
 <h3>Hola Mundo</h3>
</body>
</html>
`.trim(),

  cssTextDecoration: `
<!DOCTYPE html>
<html>
<head>
<style>
h1 {
 text-decoration: overline;
}
h2 {
 text-decoration: line-through;    
} 
h3  {
 text-decoration: underline;
}
</style>
</head>

<body>
 <h1>Titulo 1</h1>
 <h2>Titulo 2</h2>
 <h3>Titulo 3</h3>
</body>
</html>
`.trim(),

  cssTextTransform: `
<!DOCTYPE html>
<html>
<head>
<style>
h1 {
 text-transform: uppercase;
}
h2 {
 text-transform: lowercase;
}
h3  {
 text-transform: capitalize;
}
</style> 
</head>

<body>
 <h1>Texto en mayúscula</h1>
 <h2>Texto En Minúscula</h2>
 <h3>texto mayúscula</h3>
</body>
</html>
`.trim(),

  cssTextSpacing: `
<!DOCTYPE html>
<html>
<head>
<style>
h3 {
 letter-spacing: 4px;
}
p {
 letter-spacing: -2px;
}
</style>
</head>

<body>
 <h3>Aumente el espacio de las palabras en este titulo</h3>
 <p>Disminuyo el espacio de las palabras en este párrafo</p>
</body>
</html>
`.trim(),

  // text-font

  cssTextfont: `
<!DOCTYPE html>
<html>
<head>
<style>
h3 {
 font-family: Arial, Helvetica, sans-serif;
}
h4 {
 font-family: 'Times New Roman', Times, serif;
}
</style>
</head>

<body>
 <h3>Un párrafo usando Arial</h3>
 <h4>Un párrafo usando Times New Roman</h4>
</body>
</html>
`.trim(),

  cssTextfontStyle: `
<!DOCTYPE html>
<html>
<head>
<style>
p.normal {
 font-style: normal;
}
p.italic {  
 font-style: italic;
}
p.oblique {
 font-style: oblique;
}
</style>
</head>

<body>
 <p class="normal">Un párrafo usando texto normal</p>
 <p class="italic">Un párrafo usando texto italico</p>
 <p class="oblique">Un párrafo usando texto oblique</p>
</body>
</html>
`.trim(),

  cssTextfontSize: `
<!DOCTYPE html>
<html>
<head>
<style>
h3 {
 font-size: 48px;
}
p {
 font-size: 2rem;
} 
</style>
</head>

<body>
 <h3>Titulo h3 con tamaño 48px</h3>
 <p>Titulo parrafo con tamaño 2rem</p>
</body>
</html>
`.trim(),

  cssTextfontWeight: `
<!DOCTYPE html>
<html>
<head>
<style>
p.normal {  
 font-weight: normal;  
}
p.oscuro {
 font-weight: bold;
}
p.claro {
 font-weight: 300;
}
</style>
</head>

<body>
 <p class="normal">normal</p>
 <p class="oscuro">negrita</p>
 <p class="claro">claro</p>
</body>
</html>
`.trim(),

  // Display CSS

  cssDisplayBlock: `
<!DOCTYPE html>
<html>
<head>
<style>
div {  
  border: 1px solid orange;
}
</style>
</head>

<body>
  <div>Un div es un elemento block-level.</div> 
</body>
</html>
`.trim(),

  cssDisplayInline: `
<!DOCTYPE html>
<html>
<head>
<style>
span {  
  border: 1px solid orange;
}
</style>
</head>

<body>
  <p>
    Esto es un párrafo, 
    <span>span es un elemento en línea</span>
  </p>
</body>
</html>
`.trim(),

  // Altura y Anchura

  cssHeight: `
<!DOCTYPE html>
<html>
<head>
<style>
.ejemplo_altura {
 height: 150px;
 background-color: rgb(77, 149, 231);
 color: white;
}
</style>
</head>

<body>
 <div class="ejemplo_altura">Este elemento tiene una Altura de 200px y una Anchura de 25%</div>
</body>
</html>
`.trim(),

  cssWidth: `
<!DOCTYPE html>
<html>
<head>
<style>
.ejemplo_anchura {
 width: 50%;
 background-color: rgb(77, 149, 231);
 color: white;    
}
</style>
</head>

<body>
 <div class="ejemplo_anchura">Este elemento tiene una Altura de 50px y una Anchura de auto</div>
</body>
</html>
`.trim(),

  cssWidthHeight: `
<!DOCTYPE html>
<html>
<head>
<style>
.ejemplo_anchura {
  height: 5rem;
  width: 75%;
  padding: 1rem;
  text-align: center;
  background-color: rgb(77, 149, 231);
  color: white;
}
</style>
</head>

<body>
 <div class="ejemplo_anchura">Altura: 5rem y Ancho: 75%</div>
</body>
</html>
`.trim()

}

export default snippets
